
import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react"; 
import axios from "axios";
import { WindowSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PostEditModal from "./PostEditModal";
import LikeButton from "./LikeButton";
import PostCommentButton from "./PostCommentButton";
import { domainURI } from "../config";
// import SubmitCommentModal from './SubmitComment';
const DeleteVideoButton = ({userID,postID,RefreshState}) => {
  const [deleteID, setDeleteID] = useState(postID);
   
const [secToken,setsecToken] = useState(() => {
  if (
    JSON.parse(localStorage.getItem('token'))
  ) {
    return JSON.parse(localStorage.getItem('token'));
  } else { return '' }
  }
);

//console.log(postID+"deletebutton"+userID)
      
const deletePost =(a)=> {
        //console.log("deletepost"+a);
        if(a)
        {    
            axios.delete(domainURI+'/api/Pictures/'+a
            ,{
               headers: { 
                  'Accept': 'application/json',
                  'Access-Control-Allow-Credentials':true,
                  'Access-Control-Allow-Origin' : '*',
                  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                  'Authorization': 'Bearer '+ secToken.Token 
                  }, responseType: "json",
              }     
        )
        .then(response=>{
   // console.log("deletebuttontest")
         })
         .catch(error => {
           //console.log("Encountered an error with fetching and parsing data",  error);
        }
      );    
    
    }else{
      //setErrorMessage("You need to login to delete your post.");
    }
  window.location.reload();
    } 

    const deleteButtonClick = (event,p) => {
     
     //event.preventDefault();
 deletePost(p);
 RefreshState();
 //window.location.reload();
   };


return(
        <>          
          <button className="ovalbutton" onClick={(event)=>{deleteButtonClick(event,postID)}}>删除</button>          
      </>
    )
  };
   
export default DeleteVideoButton;