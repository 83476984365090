import 'draft-js/dist/Draft.css';
import draftToHtml from "draftjs-to-html";
import 'draft-js/dist/Draft.css';
import { Editor, EditorState, convertToRaw,convertFromRaw } from "draft-js";
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { memo } from "react";
import PostItem from './PostItem';
import VideoItem from './VideoItem';
// import MorePosts from './MorePosts';

   //https://medium.com/@blue.sin9k/managing-pop-ups-using-react-router-7ef10ef630a0
   //https://www.linkedin.com/pulse/how-create-private-protected-route-react-router-v6-bikash-sharma/?trk=articles_directory
   //npm install draft-js
   //https://medium.com/how-to-react/different-ways-to-loop-through-arrays-and-objects-in-react-39bcd870ccf
  
   import { domainURI } from "../config";

//   const PostList = (props) => {
function PostList(props){
  let [morePostList, setMorePostList] = useState([]);
let [PostList, setPostList] = useState([]);
let [pageNumber, setPageNumber] = useState(1);
let [pageSize, setPageSize] = useState(5); 
let [refresh, setRefresh] = useState([]);
const [scrollTop, setScrollTop] = useState(0);

  
//const [userid, setUserID] = useState(props.userID);

//https://localhost:44340/odata/GongLuePosts?pageNumber=2&pageSize=2
//domainURI + '/odata/GongLuePosts?pageNumber='+pageNumber+'&pageSize='+pageSize,{
const fetchPostData = useCallback(()=>{ 
 
axios
.get(
  domainURI+'/api/PostList/GetGongLueList?userID=0&Refreshed='+refresh+'&pageNumber='+pageNumber+'&pageSize='+pageSize,{
     headers: { 
      'Accept': 'application/json',
      'Access-Control-Allow-Credentials':true,
     'Access-Control-Allow-Origin' : 'https://travbar.com,https://www.travbar.com,http://localhost:3000',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }, responseType: "json",
  }
 
)
 .then(response=>{
 //console.log("subdata"+JSON.stringify(response.data));

 setPostList(response.data);
 setMorePostList(morePostList => [...morePostList, ...response.data]);
})
.catch(error => {
console.log(
  "Encountered an error with fetching and parsing data",
  error
);
}
);

},[refresh,pageNumber]) 


// useEffect(() => {
//   //setUserID(props.userID)  
  
//   //setMorePostList(PostList);
//   //setPageNumber(pageNumber+1);
// }, [refresh,pageNumber]);



useEffect(() => {
  fetchPostData();
  // const handleScroll = (event) => {
  //   setScrollTop(window.scrollY);
  //   var element = document.querySelector('#key');
  //   var position = element.getBoundingClientRect();

	// // checking whether fully visible
	// if(position.top >= 0 && position.bottom <= window.innerHeight) {
	// 	console.log('Element is fully visible in screen');
	// }
  //   console.log(window.scrollY);
  // };

  // window.addEventListener('scroll', handleScroll);

  // return () => {
  //   window.removeEventListener('scroll', handleScroll);
  // };
}, [pageNumber]);






const RefreshState = () => {
  setRefresh((c) => c + 1);
  //fetchPostData();
  //window.location.reload();
  //console.log("refreshed"+refresh)
};

//console.log(refresh+"refreshPostList"+props.userID+(PostList.length<pageSize))

const onMoreButtonClick = () => {
  setPageNumber(pageNumber+1);
}

//alert(refresh);
return(
<>  
<div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "left" }} >
       {
          morePostList.map((p) =>(p.FileType===0)?
           <PostItem key={p.ID} userID={props.userID} RefreshState={RefreshState} value={{ PostID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
          :
          <VideoItem key={p.ID} userID={props.userID}  RefreshState={RefreshState} value={{ VideoID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
         )
        }
</div>
     {/* {errorMessage && <div className="fail">Uh oh... you need login or you don't have any posts</div>} */}
     {

       (PostList.length ? ((PostList.length<pageSize)? "": <button className="ovalbutton"  onClick={()=>onMoreButtonClick()}>下一页</button>):"下载中，请耐心等待。。。")
     } 
    
</>
)
  
}
export default PostList