import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { memo } from "react";
import { domainURI } from "../config";
import {
  useParams
  } from "react-router-dom";
 
//https://www.digitalocean.com/community/tutorials/css-css-grid-layout-fr-unit

export default function Follow(props){

  let {useridstring,displayname} = useParams();
  let [moreFollowList, setMoreFollowList] = useState([]);
  let [followList, setFollowList] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(5); 
  
  let [userID, setUserID] = useState();
  const [isActive, setIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
      
 console.log("userID"+useridstring)
  const [following, setFollowing] = useState(0);

  const [followed, setFollowed] = useState(0);

  const fetchFollowTotalData = useCallback((ID)=>{
    if(ID)
    {
    axios.get(domainURI+'/api/NumberOfFollowers/'+ID,
      {
        headers: { 
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials':true,
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
         }, responseType: "json",
      } 
      ).then(response=>{
        setFollowed(response.data.followed);
        setFollowing(response.data.following);
      //console.log("following data"+JSON.stringify(response.data));
      
      })
      .catch(error => {
      alert("Encountered an error with fetching and parsing data", error);
      }
      );
    
    }
      
      },[]) 
    

    const fetchFollowData = useCallback((followparameter)=>{
      // axios.get(domainURI+'/api/FollowViews?userID='+props.userID+'&Refreshed='+refresh+'&pageNumber='+pageNumber+'&pageSize='+pageSize,
        axios.get(domainURI+'/api/FollowViews?'+followparameter+'&pageNumber='+pageNumber+'&pageSize='+pageSize,
        {
          headers: { 
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials':true,
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
           }, responseType: "json",
        } 
      ).then(response=>{
         //console.log("ttttsubdata"+JSON.stringify(response.data));
        setFollowList(response.data)
        setMoreFollowList(moreFollowList => [...moreFollowList, ...response.data]);
      })
      .catch(error => {
        alert("Encountered an error with fetching and parsing data", error);
      }
    );
  },[userID,pageNumber]) 

  useEffect(() => {
    // if(props.userID!=0)
  
    if(useridstring.includes("following"))
    {  //setMoreFollowList([]);
      let userid = useridstring.replace("following","");
      console.log("kjkjkjkj"+userid)
      setUserID(userid);
      fetchFollowTotalData(userid);
      fetchFollowData('UserID='+userID);
      setIsActive(true);
    }else{
      let userid = useridstring.replace("followed","");
      setUserID(userid);
      
      console.log("kj7777kjkjkj"+userid)
      fetchFollowTotalData(userid);
      fetchFollowData('AuthorID='+userID);  
      setIsActive(false);
      console.log("isActive"+isActive)
    }
    
     
  }, [pageNumber,userID]);

  
  const onMoreButtonClick = () => {
    setPageNumber(pageNumber+1);
  }

  const onFollowedButtonClick = () => {
    setMoreFollowList([]);
    fetchFollowData('AuthorID='+userID);   
    setIsActive(false);
  }

  const onFollowingButtonClick = () => {
    setMoreFollowList([]);
    fetchFollowData('UserID='+userID);
    setIsActive(true);
  }

  return (
  <fieldset className="postitem">
        <legend> 
          <a  className="text-align:right; float:right;text-decoration:none;"  href={"/UserPost/"+userID }>
            {displayname}
            &nbsp;
            <img className='avatar' src={domainURI+"/api/photos/"+userID} alt="avatar"
              onError={e => {
                e.currentTarget.src = domainURI+"/api/photos/10"
              }} />
          </a> 
          &nbsp;      
        </legend>
        <div className="flex-containerforbutton">
          <div className="flex-childbutton magenta">
            <button style={{ backgroundColor: isActive ? 'gray' : '',color: isActive ? 'white' : '', }} onClick={() => onFollowingButtonClick()}>Followings({following})</button>
          </div>
          <div className="flex-childbutton green">  
            <button style={{ backgroundColor: isActive ? '' : 'Gray', color: isActive ? '' : 'white', }} onClick={() => onFollowedButtonClick()}>Followers({followed})</button>
          </div>
        </div>
        <hr></hr>
        {
            moreFollowList.map
            (
              (p) =>
              <div key={p.authorid} className="flex-container">
                <div className="flex-child magenta">
                  <a className="text-align:right; float:right;text-decoration:none;" 
                     href={"/UserPost/"+(isActive ? p.authorid : p.userid) }>
                     {isActive ? p.authordisplayname : p.userDisplayName}
                  </a>
                </div>
                <div className="flex-child green">{(isActive ? p.authorbio : p.userBio) ||'暂无'}
                </div>
              </div>
            )
        }

        <div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "center" }} >
        {
          (followList.length<pageSize)? (props.userID===0? "": "No more..."): <button className="ovalbutton"  onClick={()=>onMoreButtonClick()}>More</button>
        }    
        {/* { 
          props.userID===0 && <div className="fail">Uh oh... you need login or you don't have any posts</div> 
        }   */}
        </div>

        </fieldset>)
  ;
}