import 'draft-js/dist/Draft.css';
import draftToHtml from "draftjs-to-html";
import 'draft-js/dist/Draft.css';
import { Editor, EditorState, convertToRaw,convertFromRaw } from "draft-js";
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { memo } from "react";
import CommentItem from './CommentItem';
// import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { domainURI } from "../config";
// import TreeItem from '@mui/lab/TreeItem';  
   const CommentList = ({refresh, RefreshState, value}) => {
   
    const [userid, setUserID] = useState(0);

    const tokenString = localStorage.getItem('token');
    
    useEffect(() => {
       if(tokenString)
    {
      const userToken = JSON.parse(tokenString);
      setUserID(userToken.Id)
    } else{
      setUserID(0)
  
    }
    }, []);
  

  let [commentList, setCommentList] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');

 // console.log("CommentList" +value.postID)
const fetchCommentData = useCallback(()=>{

// if(userToken)
// {
    //console.log("userTokenID"+userToken.Id)
    //const [token, setToken] = useToken();
    //console.log("userToken11"+userToken)
    //console.log("userToken11"+userToken.value.Id)
    axios.get( domainURI+'/api/PostCommentViews/'+value.postID,
      {               
        headers: { 
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials':true,
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }, responseType: "json",
      }
)
.then(response=>{
//console.log("comment List subdata"+JSON.stringify(response.data));
setCommentList(response.data)
})
.catch(error => {
console.log(
"Encountered an error with fetching and parsing data",
error
);
}
);


// }else{
// setErrorMessage("You need to login to see your post.");
// }

},[refresh]) 

useEffect(() => {
fetchCommentData()
}, [refresh]);

return(
<>
   {commentList.map((p) => 
   <fieldset  key={p.ID} >
  <legend>
    
    {<a  className="text-align: right; float: right;text-decoration:none;"  href={"/UserPost/"+p.UserID }>&nbsp;
  
  {p.DisplayName}&nbsp;
  <img className='avatar' src={ domainURI+"/api/photos/"+p.UserID} alt="avatar"   onError={e => {
          e.currentTarget.src =  domainURI+"/api/photos/10"
        }} />
   </a>}
   &nbsp;发布于 {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',hour: 'numeric',  minute: 'numeric', second: 'numeric'}).format(new Date(p.CommentDateTime))}  
   </legend>
   <CommentItem key={p.ID} RefreshState={RefreshState} value={{ CommentID: p.ID,UserID:p.UserID,CommentDateTime:p.CommentDateTime, PostID:p.PostID, Text: p.Comment }} />
   </fieldset>
    
   )}
   
</>
)
}

export default CommentList