import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { domainURI } from "../config";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
 useRouteMatch
} from "react-router-dom";
const UserInfo = ({user})=>{
const { hobbies, ID,Email,Password,LastName,FirstName,DisplayName,UserName,EmailVerified,Bio,Location,WebSite,WeChat,FaceBook,Telegram}=user||{};

//const [currentUserID, setCurrentUserID] = useState(0);

const [secToken,setsecToken] = useState(() => {
    if (
      JSON.parse(localStorage.getItem('token'))
    ) {
      return JSON.parse(localStorage.getItem('token'));
    } else { return '' }
    }
  );

//console.log(JSON.parse(localStorage.getItem('token')))
//const tokenString = localStorage.getItem('token');
//const userToken = JSON.parse(tokenString);

const [inputs, setInputs] = useState({});

const [action, setAction] = useState({});

const [following, setFollowing] = useState(0);

const [followed, setFollowed] = useState(0);


const fetchFollowData = useCallback(()=>{
if(ID)
{
axios.get(domainURI+'/api/NumberOfFollowers/'+ID,
  {
    headers: { 
      'Accept': 'application/json',
      'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
     }, responseType: "json",
  } 
  ).then(response=>{
    setFollowed(response.data.followed);
    setFollowing(response.data.following);
  //console.log("following data"+JSON.stringify(response.data));
  
  })
  .catch(error => {
  alert("Encountered an error with fetching and parsing data", error);
  }
  );

}
  
  },[ID]) 


useEffect(() => {
// const currentLoginUserInfo = () => {
//   if (
//     JSON.parse(localStorage.getItem('token'))
//   ) {
//     return JSON.parse(localStorage.getItem('token'));
//   } else { return '' }
//};
  
  //setCurrentUserID(currentLoginUserInfo.Id);
  //setToken(currentLoginUserInfo.Token);
 
  setInputs(values => ({...values,ID:ID, DisplayName: DisplayName,FirstName:FirstName,LastName:LastName,Bio:Bio,Email:Email,Location:Location,WebSite:WebSite,WeChat:WeChat,FaceBook:FaceBook,Telegram:Telegram})); 
   fetchFollowData();
   //setAction(values=>({...values,method:'PUT',url:'https://localhost:44340/api/UsersAPI/'+ID}));
  
  }, [user,ID]);

  //const token =currentLoginUserInfo?currentLoginUserInfo.Token:'';
//console.log("User ID"+userToken.Id)
const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs(values => ({...values, [name]: value}))
}

const handleSubmit = (event) => {
   event.preventDefault();
  //alert(JSON.stringify(inputs));
  saveContent(inputs) 
}

//console.log("tokentoken"+JSON.stringify(secToken));
const saveContent = (content) => {
  //const response =  axios({ method: 'patch', url: 'https://localhost:44340/odata/Users('+ID+')', data: 
  const response =  axios({ method: 'put', url: domainURI+'/api/UsersAPI/'+ID, data: 
  content 
  , 
    headers:{
    'Content-Type':'application/json',
    // 'Access-Control-Allow-Credentials':true,
    // 'Access-Control-Allow-Origin':'*',
    //'Access-Control-Allow-Methods':'PUT',
   // 'Content-Type':'application/json; charset=utf-8',
    'Authorization': 'Bearer '+ secToken.Token
  }
}
).then(function (response) {
   // console.log("then"+JSON.stringify(response))
    //alert("then"+JSON.stringify(response));
  })
  .catch(function (response) {
    //handle error
    //setErrorMessage("Something has happened.")
   //console.log("catch"+response);
   //alert(JSON.stringify(response));
  });
  
  
  
  
    }

    const handleFileInput = (e) => {
      // handle validations
      const file = e.target.files[0];
      // console.log(file.size);
      setSelectedFile(e.target.files[0]);
      //if (file.size > 1024)
      // if (file.size > 10)
      //   onFileSelectError({ error: "File size cannot exceed more than 1MB" });
      // else onFileSelectSuccess(file);
    };
 
  const [selectedFile, setSelectedFile] = useState(null);
  //console.log(secToken.Id)

const submitForm = (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    // formData.append("PhotoID", 0);
    formData.append("Image", selectedFile);
    formData.append("UserID",ID);    
    formData.append("FileName", selectedFile.name); 
    axios
      .post(domainURI+"/api/Photos", formData)
      .then((res) => {
        alert("File Upload success");
        window.location.reload();
      })
      .catch((err) => alert("File Upload Error "+err));
   

  };


return user ? (

 <fieldset className="postitem">
    <legend>{inputs.DisplayName || "User Info"}&nbsp;&nbsp;
    <Link className="nav-link"  to={"/Follow/following"+ID + "/"+inputs.DisplayName }>Following({following})</Link>&nbsp;&nbsp;&nbsp;
    <Link className="nav-link"  to={"/Follow/followed"+ID+"/"+inputs.DisplayName}>Followed({followed})</Link>
   </legend>
    {/* <img className='UserImg' alt="没照片" src={"https://localhost:44340/api/photos/"+ID} /> */}

    <img className='UserImg' src={domainURI+"/api/photos/"+ID} alt="没照片"   onError={e => {
          e.currentTarget.src = domainURI+"/api/photos/10"
        }} />




<form>
<dl className="foralldl"><dd className="formdd"></dd>
  <dt className="formdt"></dt>
</dl>
{ secToken.Id===ID ?
<dl className="foralldl">

<dt className="formdt">昵称:</dt>
<dd className="formdd">
  <input type="text" name="DisplayName" value={inputs.DisplayName || ""} onChange={handleChange}/>
</dd>

<dt  className="formdt">
名:
</dt>
<dd  className="formdd">
  <input type="text" name="FirstName" value={inputs.FirstName || ""} onChange={handleChange} />
</dd>
<dt  className="formdt">
  姓:
</dt>
<dd  className="formdd">
  <input type="text" name="LastName" value={inputs.LastName || ""} onChange={handleChange} />
</dd>
<dt  className="formdt">
  简介:
</dt>
<dd  className="formdd">
  <textarea name="Bio" value={inputs.Bio||""} onChange={handleChange} />
</dd>
{/* <dt>Email:</dt>
<dd>
  <input type="text" name="Email" value={inputs.Email || ""} onChange={handleChange}/>
</dd> */}

<dt  className="formdt">地点:</dt>
<dd  className="formdd">
  <input type="text" name="Location" value={inputs.Location || ""} onChange={handleChange}/>
</dd>
{/* <dt>Web Site:</dt>
<dd>
  <input type="text" name="WebSite" value={inputs.WebSite || ""} onChange={handleChange}/>
</dd>

<dt>WeChat:</dt>
<dd>
  <input type="text" name="WeChat" value={inputs.WeChat || ""} onChange={handleChange}/>
</dd>

<dt>Face Book:</dt>
<dd>
  <input type="text" name="FaceBook" value={inputs.FaceBook || ""} onChange={handleChange}/>
</dd>

<dt>Telegram:</dt>
<dd>
  <input type="text" name="Telegram" value={inputs.Telegram || ""} onChange={handleChange}/>
</dd> */}


</dl>:
<dl  className="foralldl">

<dt  className="formdt">昵称:</dt>
<dd  className="formdd">
  {inputs.DisplayName || ""}
</dd>

<dt className="formdt">
名:
</dt>
<dd  className="formdd">
 { inputs.FirstName || ""}
</dd>
<dt  className="formdt">
  姓:
</dt>
<dd  className="formdd">
{inputs.LastName || ""}
</dd>
<dt  className="formdt">
  简介:
</dt>
<dd  className="formdd">
  {inputs.Bio||""} 
</dd>
{/* <dt>Email:</dt>
<dd>
{inputs.Email || ""}
</dd> */}
<dt className="formdt">地点:</dt>
<dd  className="formdd">
  {inputs.Location || ""} 
</dd>
{/* <dt>Web Site:</dt>
<dd>
  {inputs.WebSite || ""}
</dd>

<dt>WeChat:</dt>
<dd>
  {inputs.WeChat || ""} 
</dd>

<dt>Face Book:</dt>
<dd>
  {inputs.FaceBook || ""}
</dd>

<dt>Telegram:</dt>
<dd>
  {inputs.Telegram || ""} 
</dd> */}

</dl>

}

{ secToken.Id===ID && <button  onClick={(e)=>handleSubmit(e)}>更新</button> } 
</form>

<form>


{
secToken.Id===ID && <> <dl  className="foralldl">
<dt className="formdt">添加/更换图片:</dt>
<dd className="formdd">

<input type="file" onChange={handleFileInput}/>

</dd></dl>
<button  className="ovalbutton" onClick={submitForm}>上传</button>
</>
}

</form>

</fieldset>

):<p>Loading...</p>;

}

export default UserInfo;