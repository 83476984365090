import 'draft-js/dist/Draft.css';
import draftToHtml from "draftjs-to-html";
import 'draft-js/dist/Draft.css';
import { Editor, EditorState, convertToRaw,convertFromRaw } from "draft-js";
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { memo } from "react";
import CommentItem from './CommentItem';
import { domainURI } from "../config";

   const CommentFeedBackList = ({ RefreshState,RefreshCommentItemState,refresh,CommentID}) => {

    const [userid, setUserID] = useState(0);

    const tokenString = localStorage.getItem('token');
         
  // const tokenString = localStorage.getItem('token');
  // const userToken = JSON.parse(tokenString);
   
  let [commentList, setCommentList] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  //let [refresh, setRefresh] = useState([]);

 // console.log("CommentList" +JSON.parse(props.value))
const fetchCommentData = useCallback(()=>{

//  if(userid)
//  {
    //console.log("userTokenID"+userToken.Id)
    //const [token, setToken] = useToken();
    //console.log("userToken11"+userToken)
    //console.log("userToken11"+userToken.value.Id)
    axios.get(domainURI+'/api/CommentComments/'+CommentID,
      {               
        headers: { 
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials':true,
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          }, responseType: "json",
      }
)
.then(response=>{
//console.log("subdata"+JSON.stringify(response.data));
setCommentList(response.data)
})
.catch(error => {
console.log(
"Encountered an error with fetching and parsing data",
error
);
}
);


// }else{
// setErrorMessage("You need to login to see your post.");
// }



},[refresh]) 


useEffect(() => {

  if(tokenString)
  {
    const userToken = JSON.parse(tokenString);
    setUserID(userToken.Id)
  } else{
    setUserID(0)

  }

fetchCommentData()
}, [refresh]);

// const AVATAR = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';


return(
<>
{/* <div className="CommentDiv" style={{ backgroundColor:"AliceBlue",border:"1px", paddingLeft: "50px", fontSize: "17px", textAlign: "center" }} > */}




     {commentList.map((p) =>
     <fieldset  key={p.ID} >
  <legend>{<a  className="text-align: right; float: right;text-decoration:none;"  href={"/UserPost/"+p.UserID }>&nbsp;
  {p.DisplayName}&nbsp;  
  <img className='avatar' src={domainURI+"/api/photos/"+p.UserID} alt="avatar"   onError={e => {
          e.currentTarget.src = domainURI+"/api/photos/10"
        }} />
  
   &nbsp;Posted on {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',hour: 'numeric',  minute: 'numeric', second: 'numeric'}).format(new Date(p.CommentDateTime))}</a>  }
   </legend>
     <CommentItem key={p.ID}  RefreshState={RefreshState}  value={{ CommentID: p.ID,UserID:p.UserID, CommentDateTime:p.CommentDateTime, CommentType:p.CommentType, Text: p.Comment }} />
     </fieldset>
     
     )}
  

   {errorMessage && <div className="fail">Uh oh... you need login or you don't have any posts.{errorMessage}</div>}

</>
)

}

export default CommentFeedBackList