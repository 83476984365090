import PostItem from './PostItem';
import CurrentUserLoader from './CurrentUserLoader';
import UserInfo from './UserInfo';
import draftToHtml from "draftjs-to-html";
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import {
useParams
} from "react-router-dom";
import { domainURI } from "../config";
import VideoItem from './VideoItem';
function UserPost(props){
  let { value } = useParams(); 
  let [morePostList, setMorePostList] = useState([]);
  let [PostList, setPostList] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(5); 
  let [refresh, setRefresh] = useState([]);
  
  const [errorMessage, setErrorMessage] = useState('');
  const [userid, setUserID] = useState(props.userID); 

const fetchPostData = useCallback(()=>{

axios.get(domainURI+'/api/PostListViews?userID='+value+'&Refreshed='+refresh+'&pageNumber='+pageNumber+'&pageSize='+pageSize,
{
  headers: { 
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials':true,
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   }, responseType: "json",
} 
).then(response=>{
//console.log("subdata"+JSON.stringify(response.data));
setPostList(response.data)
setMorePostList(morePostList => [...morePostList, ...response.data]);
})
.catch(error => {
alert("Encountered an error with fetching and parsing data", error);
}
);
},[pageNumber]) 


useEffect(() => {
  if(value!==0)
    fetchPostData();
}, [pageNumber]);

const RefreshState = () => {
  setRefresh((c) => c + 1);
  //window.location.reload();
};

const onMoreButtonClick = () => {
  setPageNumber(pageNumber+1);
}

return(
<>
<div style={{ backgroundColor:"Beige", padding: "10px", fontSize: "16px", textAlign: "left" }} >
<CurrentUserLoader userID={value} loginUser={props.userID} children={ <UserInfo/>}> 
</CurrentUserLoader>
</div>
<br></br>



    <div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "left" }} > 
    {
      morePostList.map((p) =>(p.FileType===0)?
      <PostItem key={p.ID} userID={props.userID} RefreshState={RefreshState} value={{ PostID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
      :
      <VideoItem key={p.ID} userID={props.userID}  RefreshState={RefreshState} value={{ VideoID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
     )
    }
    </div>
    {
      (PostList.length<pageSize)?"": <button className="ovalbutton"  onClick={()=>onMoreButtonClick()}>More</button>
    }   

   {errorMessage && <div className="fail">{errorMessage}</div>}
  
</>
)
 
}


export default UserPost