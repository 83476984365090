//Navbar.js
import React, { useEffect, useState, useCallback, Fragment } from 'react';
import SignUpModal from "../SignUpModal"
import LoginModal from "../LoginModal"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch, useNavigate, Navigate
} from "react-router-dom";
import { domainURI } from "../../config";
import './NavbarForPhone.css';

const NavbarForPhone = (props) => {
  // let { searchTextP } = useParams();
  // alert(searchTextP)
  const [searchText, setSearchText] = useState("");
  let navigate = useNavigate();

  let onSearchFormSubmit = (e) => {
    e.preventDefault();
    setSearchText(searchText);
    if (searchText) {
      navigate("/SearchResult/" + searchText)
    } else {
      alert("input something")
    }
    //console.log("token in login:"+email+password)
    //  alert("seach button clicked.")

  };

  return (


    <header >

      <nav>
        <div className="navbar">
          <div className="container nav-container">
            <input className="checkbox" type="checkbox" name="" id="" />
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>

            <div className="searchbox">
              <form inline="true">
                <input type="search" placeholder="搜索 ..." value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <button type="submit" onClick={(e) => onSearchFormSubmit(e)} color="info"><i className="fa fa-fw fa-search"></i></button>
              </form>
            </div>
            <div className="logo">
              <a href={"/UserPost/" + props.userID}>
                <img className='UserImg' src={domainURI + "/api/photos/" + props.userID} alt="没照片"
                  style={{ width: 25, height: 25 }} onError={e => {
                    e.currentTarget.src = domainURI + "/api/photos/10"
                  }} />
              </a>
            </div>

            <div className="menu-items">
              <li><a className="nav-link" href="javascript:void(0);">&nbsp;</a></li>
              <li><a className="nav-link" href="javascript:void(0);">&nbsp;</a></li>
              <li><a className="nav-link" href="javascript:void(0);">&nbsp;</a></li>
              <li><a className="nav-link" href="javascript:void(0);">&nbsp;</a></li>
              <li><a className="nav-link" href="javascript:void(0);">&nbsp;</a></li>
              <li><a className="nav-link" href="javascript:void(0);">&nbsp;</a></li>
              <li> <a href="/CreatePost">发帖</a> </li>
              <li><a className="nav-link" href="/">最新</a></li>
              <li><a className="nav-link" href="/PopularPosts">流行文本</a></li>
              <li> <a className="nav-link" href="/PopularVideos">流行视频</a></li>
              <li> <a className="nav-link" href="/MyPost">我的贴</a></li>
            
              <li> <LoginModal userID={props.userID} /></li>
              <li>
                {
                  props.userID === 0 && <SignUpModal />
                }
              </li> 
              <li>  <a className="nav-link" href="/ContactUs"><i className="fa fa-fw fa-envelope"></i>联系我们</a></li>
            </div>
          </div>
        </div>
      </nav>


    </header>

  )
};

export default NavbarForPhone;