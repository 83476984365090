import React, { useEffect, useState, useCallback, Fragment } from "react";
import axios from "axios";
import { domainURI,REACT_APP_BUCKET_NAME,REACT_APP_REGION, REACT_APP_ACCESS,REACT_APP_SECRET} from "../config";
import AWS from 'aws-sdk';

const S3_BUCKET =REACT_APP_BUCKET_NAME;
const REGION =REACT_APP_REGION;


AWS.config.update({
    accessKeyId: REACT_APP_ACCESS,
    secretAccessKey: REACT_APP_SECRET
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})
const VideoForm = (props) => {

   const [postLocation, setPostLocation] = useState("");
//   const [uploading, setUploading] = useState("");
//   const handleFileInput = (e) => {
//           // handle validations
//           const file = e.target.files[0];
//           console.log(file);
//           setSelectedFile(e.target.files[0]);
//           //if (file.size > 1024)
//           // if (file.size > 10)
//           //   onFileSelectError({ error: "File size cannot exceed more than 1MB" });
//           // else onFileSelectSuccess(file);
//         };


       const [userid, setUserID] = useState(props.userID);

      const [secToken,setsecToken] = useState(() => {
        if (
          JSON.parse(localStorage.getItem('token'))
        ) {
          return JSON.parse(localStorage.getItem('token'));
        } else { return '' }
        }
      );

//       const [selectedFile, setSelectedFile] = useState(null);
//     //console.log(props.userID + props.location)
       
    
//     // const saveSubmitForm = (e) => {
//     //     e.preventDefault();
//     //     //console.log(selectedFile); 
//     //     const formData = new FormData();
//     //     formData.append("FileName", selectedFile.name);
//     //     formData.append("Image", selectedFile);
//     //     formData.append("FileSize", selectedFile.size);
//     //     formData.append("PostID", 0);
//     //     formData.append("FileType", selectedFile.type);
//     //     formData.append("UserID", props.userID);
//     //     formData.append("Location", postLocation);
//     //     formData.append("Published", false);
//     //     axios
//     //       .post("https://localhost:44340/api/File", formData)
//     //       .then((res) => {
//     //         //alert("File Upload success");
//     //         //window.location.reload();
//     //       })
//     //       .catch((err) => alert("File Upload Error"+err));
       
//     //   };


const [deviceType, setDeviceType] = useState('pc');

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android|Windows Phone/i.test(navigator.userAgent);
    const isTablet = /iPad|Android|Windows/i.test(navigator.userAgent);
    if (isMobile) {
      if (isTablet) {
        setDeviceType('tablet');
      } else {
        setDeviceType('phone');
      }
    }
  }, []);

const [progress , setProgress] = useState(0);
const [selectedFile, setSelectedFile] = useState(null);
const [selectedVideoType, setSelectedVideoType] = useState("Library");
const [fileKey , setFileKey] = useState();
const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
}
const handleVideoTypeInput = (e) => {
  //alert(e.target.value)
  setSelectedVideoType(e.target.value);
}
      const publishSubmitForm = (e) => {
        e.preventDefault();
  
        axios
          (
            { method: 'POST', url: domainURI+"/api/Pictures/", 
            data:  
              {
                "FileName":selectedFile.name,"FileSize":selectedFile.size,"PostID":0,"FileType":selectedFile.type,"UserID":userid,"Location":postLocation,"FileExtension":selectedFile.name.split('.').pop(),"Published":true
              }
            
          , headers: {
            'Accept': 'application/json',
             'Access-Control-Allow-Origin' : 'https://www.travbar.com,http://www.travbar.com,http://localhost:3000',
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': 'Bearer '+ secToken.Token 
            }}                 
          )
          .then((res) => {
//console.log(JSON.stringify(res));
            uploadFile(selectedFile,res.data);
            //window.location.reload();
          })
          .catch((err) => alert("上传出错请稍后再试。"));
       
      };

const uploadFile = (file,picid) => {
    //setFileKey(userid+'/'+file.name);
    const params = {
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key:userid+'/video/'+picid+file.name 
    };

    myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            setProgress(Math.round((evt.loaded / evt.total) * 100));
           if(evt.loaded===evt.total && evt.loaded!==0)
           {
               //publishSubmitForm();
               alert("上传成功。");
               window.location.reload();
              }           
        })
        .send((err) => {
            if (err) alert("上传出错请稍后再试。"+err);
        })
}


const fileInputStyle = {
  backgroundColor: "#8f8c8c",
  border: "none",
  color: "white",
  // padding: "8px 16px",
  textAlign: "center",
  /* font-size: 16px; */
  margin: "4px 2px",
  opacity: "0.6",
  transition: "0.3s",
  display: "block",
  cursor: "pointer",
  width:"50%"
};


return <div>
    <div  style={{color: (progress!==0 && progress!==100)?'red':'black',fontWeight:  (progress!==0 && progress!==100) ? 'bold' : 'normal'}}> 上传进度： {progress}%</div>
    <dl  className="foralldl">   
           <dt className="formdt">主题:</dt>  
 <dd  className="formdd"><input type="text" maxLength="50" size="40" style={{width:"80%"}}  required value={postLocation} onChange={(e) => setPostLocation(e.target.value)}/>
 </dd>

{
(deviceType==="tablet"||deviceType==="phone")&&(
<>
  <dt  className="formdt">获取视频方式</dt>
 <dd  className="formdd">
 <div onChange={handleVideoTypeInput}>
                <input type="radio" value="Capture" defaultChecked={selectedVideoType === 'Capture'} name="PictureSource" /> 相机实录
                &nbsp;<input type="radio" value="Library" defaultChecked={selectedVideoType === 'Library'} name="PictureSource" />视频库
 </div>
  </dd></>
)
} 

 <dt  className="formdt">上传视频</dt>
 <dd  className="formdd">  
  <label style={fileInputStyle}>
  {
  (deviceType==="tablet"||deviceType==="phone")?(selectedVideoType === 'Library'?<input type="file" accept="video/mp4,video/x-m4v,video/*" onChange={handleFileInput}/>:
    <input type="file" accept="video/mp4,video/x-m4v,video/*" capture="environment" onChange={handleFileInput}/>
    ):(<input type="file" accept="video/mp4,video/x-m4v,video/*" onChange={handleFileInput}/>)
  }
    
    </label>
    </dd>
      </dl><br></br>
    {/* <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button> */}
    {props.userID!==0 && <button  disabled={(!postLocation || !selectedFile ||  (progress!==0 && progress!==100))}  className="uploadbutton"  onClick={(e) => publishSubmitForm(e)}>上传</button>}
</div>
}

export default VideoForm