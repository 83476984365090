
import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react"; 
import axios from "axios";
import { domainURI } from "../config";
const LikeButton = ({userID, postID,postType}) => {

//const [userid, setUserID] = useState(userID);

const [ count, setCount ] = useState(0);
const [ selfCount, setSelfCount ] = useState(0);
const [ btnColor, setbtnColor ] = useState();
   
const [secToken,setsecToken] = useState(() => {
  if (
    JSON.parse(localStorage.getItem('token'))
  ) {
    return JSON.parse(localStorage.getItem('token'));
  } else { return '' }
  }
);
//console.log("postType in likebutton "+postType)
//setUserID(userID);
const fetchPostLikesData = useCallback(()=>{
  
  const response = axios({ method: 'get', url: domainURI+'/api/PostLikes/'+postID+'/'+userID,
   headers: {
  'Access-Control-Allow-Credentials':true,
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json; charset=utf-8'
  }, responseType: "json"})
  .then(function (response) {
       //console.log("data"+JSON.stringify(response.data));
      const content = response.data;
      setCount(content.Cnt)
      //console.log("selfme"+content.MeCnt)
      setSelfCount(content.MeCnt)
      //console.log("self"+selfCount)
      if(content.MeCnt>0)
      {
        setbtnColor("red");
        //console.log("red")
      }else{
        setbtnColor("green");
        //console.log("green")
      }
      
   })
   .catch(function (response) {
          //handle error
          //setErrorMessage("Something has happened.")
         //console.log(response);
        });
      },[]) 



      useEffect(() => {
        fetchPostLikesData();    
      }, [Location.key]);
   
     
const LikeButtonClick = (a) => {
    if(selfCount>0)
    {
       
      setCount(a-1);
      setSelfCount(0);
      setbtnColor("green");
      //console.log(selfCount)
      const response = axios
      .delete(
        domainURI+'/api/PostLikes/'+postID+'/'+userID+'?postType='+postType,{
           headers: { 
            'Access-Control-Allow-Credentials':true,
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'DELETE',
          'Authorization': 'Bearer '+ secToken.Token 
        }, responseType: "json",
        }
      
      )
    
    }else{

            setCount(a+1);
            setSelfCount(1);
            setbtnColor("red");

            const response = 
            axios({ method: 'post', url: domainURI+'/api/PostLikes', data: {         
              "UserID":JSON.stringify(userID),
              "Likes":"true",
              "PostID":postID,
              "PostType":postType

            }, headers: {
              'Access-Control-Allow-Credentials':true,
              'Access-Control-Allow-Origin' : '*',
              'Access-Control-Allow-Methods':'POST',
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': 'Bearer '+ secToken.Token 
            }}).then(function (response) {
                        
              //console.log(response.data);
            })
            .catch(function (response) {
              //handle error
              //setErrorMessage("Something has happened.")
             //console.log(response);
            });
    }   
  }
 

return(
        <>      
        <button  className="ovalbutton" onClick={() => (userID === undefined || userID === null||userID===0)?alert("点赞需登录"):LikeButtonClick(count)}>点赞({count})</button>         
        </>
    )
  };
   
export default LikeButton