import Modal from '@mui/material/Modal';
 import Button from '@mui/material/Button';
 import TextField from '@mui/material/TextField';
 import Dialog from '@mui/material/Dialog';
 import DialogActions from '@mui/material/DialogActions';
 import DialogContent from '@mui/material/DialogContent';
 import DialogContentText from '@mui/material/DialogContentText';
 import DialogTitle from '@mui/material/DialogTitle';
 import axios from 'axios';
 import React, { useRef,  useEffect,  useState,useCallback, Fragment } from "react";
 import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch, useNavigate
  } from "react-router-dom";
   import { useToken } from '../auth/useToken';
   import ReCAPTCHA from "react-google-recaptcha";
   import { domainURI } from "../config";
//import ReCAPTCHA from 'react-google-recaptcha';
//https://javascript.plainenglish.io/integrate-google-recaptcha-v2-invisible-with-react-and-nodejs-9d119c94433b
//https://auth0.com/docs/quickstart/spa/react/01-login
//https://developers.google.com/recaptcha/docs/faq
//https://learn.microsoft.com/en-us/aspnet/web-api/overview/security/individual-accounts-in-web-api

const LoginModal = props => {
//6LdG1qwiAAAAAKdUIqRYEB6e0uDzR848s05NS72x
//6LdG1qwiAAAAABjuV3roiYVlFFniI62WJQRl6gIJ
const [loginResponse, setLoginResponse] = useState({});
  const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [checked, setChecked] = useState(false);
  // const tokenString = localStorage.getItem('token');
  // const userToken = JSON.parse(tokenString);
  //const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

  const regEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

let [showLogIn, setShowLogIn] = useState(false);
let [showLogOut, setShowLogOut] = useState(false);
const [errorMessage, setErrorMessage] = useState('');

//  const logindata = 
 const { logintoken,Id,statusCode,reasonPhrase, httpStatusCode,tokenExpirationTime,isEmailVerified,Email,isEmailRegisted,isPasswordValid}={};

let handleClose = () => {

  setEmail("");
  setPassword("");
  setChecked("");
  setErrorMessage("")
  setShowLogIn(false);

}
//let handleClose = () => {setShowLogIn(false);window.location.reload(false)}
let handleShow = () => setShowLogIn(true);


const handleShowLogout = () => {
  setShowLogOut(true);
  localStorage.removeItem('token');
  //window.location.reload(false);
};

const handleCloseLogout = () => {
  setShowLogOut(false);
  //localStorage.removeItem('token');
  window.location.reload(false);
};

  const [token, setToken] = useToken();
  let navigate = useNavigate();
  const recaptchaRef = useRef(null)

  const handleSubmit = async () => {
    
  const captchaToken = await recaptchaRef.current.executeAsync();
  recaptchaRef.current.reset();
    
    // Pass this token to your server for validation...    
    // Sample
    // const res = await axios.post(
    //   API_URL,
    //   { 
    //     fromData,
    //     captchaToken
    //   }
    // );
  }

let onLoginFormSubmit = (e) => {
  //e.preventDefault();
  //console.log("token in login:"+email+password)

  if(regEx.test(email) && email && password)
  {
      const response = axios.post
      (domainURI+'/api/login', 
        {
          "Username":email,"Password":password
        }               
        
        )
        .then(function (response) {
          //const { token } = response.data;
          // this.Token = "";
          // this.Id = 0;
          // this.responseMsg = new HttpResponseMessage() { StatusCode = System.Net.HttpStatusCode.Unauthorized };
          // this.TokenExpirationTime= DateTime.Now;
          // this.isEmailVerified = false;
          // this.Email = "";
          // this.isEmailRegisted = true;
          // this.isPasswordValid = true;

          setLoginResponse(values => ({...values,logintoken:response.data.Token,statusCode:response.data.responseMsg.StatusCode,
            reasonPhrase:JSON.stringify(response.data.responseMsg.ReasonPhrase),
            tokenExpirationTime:response.data.TokenExpirationTime,
            isEmailVerified:response.data.isEmailVerified,
            Email:response.data.Email,
            isEmailRegisted:response.data.isEmailRegisted,
            isPasswordValid:response.data.isPasswordValid         
          
          }));

   //console.log("response"+JSON.stringify(response.data)); 
         // console.log("mess1"+loginResponse.reasonPhrase); 
// console.log("reasonphrase"+loginResponse.reasonPhrase+(JSON.Pasre(loginResponse.reasonPhrase)==="Unauthorized")); 
//           console.log("reasonphrase"+loginResponse.isEmailVerified); 
//           console.log("reasonphrase"+loginResponse.isEmailRegisted);
//           console.log("reasonphrase"+loginResponse.isPasswordValid+(loginResponse.isPasswordValid===false));
          
        if(loginResponse.reasonPhrase==="Unauthorized")
        {
          
          if(loginResponse.isPasswordValid===false)
          {
            setErrorMessage("登陆失败。密码不对。");
          }
          else if(loginResponse.isEmailVerified===false)
          {  
            setErrorMessage("登陆失败。邮件还没确认。");
          }
          else if (loginResponse.isEmailRegisted===false) {
            setErrorMessage("登陆失败。邮件还没注册。");
          } 
          
        }else
        {
          //console.log("reasonphrasesettoken"); 
            setToken(response.data);
            setShowLogIn(false);  
            window.location.reload();   
        }
    })
        .catch(function (error) {
          //handle error
          if(error.message.includes('555'))
          {
            setErrorMessage("登陆失败。邮件还没注册。");
 
          }          
          else if(error.message.includes('556'))
          {  
            setErrorMessage("登陆失败。邮件还没确认。");
          }
          else if(error.message.includes('557'))
          {
            setErrorMessage("登陆失败。密码不对。");
          }
        // else          
        //   {
        //     setErrorMessage("登陆失败。请检查邮件和密码。")
        //   }      
        //   console.log("error"+JSON.stringify(error));   
        //   console.log("login failed"+error); 
        //   console.log("login failed"+error.status); 
        });
        //setShowLogIn(false);   
  }else{
        if(!email)
        {
           setErrorMessage("请提供邮件")
        }        
        else if(!regEx.test(email))
        {
          setErrorMessage("邮件不符规则")
       // alert("Please fill out all the fields.")
        }else{
          //alert(email)
          setErrorMessage("请提供密码")
        }

      }       
};

 
// const onChange=(response)=> {
//   this.setState({
//     'g-recaptcha-response': response
//   });
// }

 const handleChange = () => {
   setChecked(!checked);
 };



return(
<>
<div className="d-flex align-items-center justify-content-center">
 
 { props.userID? <Link onClick={handleShowLogout} to="/">退出</Link> 
            :<Link onClick={handleShow} to="/"><i className="fa fa-fw fa-user"></i>登录</Link>} 
</div>

{
props.userID?

<Dialog open={showLogOut} onClose={handleClose}>
<DialogTitle></DialogTitle>
<DialogContent>
你已退出
</DialogContent>
        <DialogActions>
          <Button onClick={()=>handleCloseLogout()}>Close</Button>        
        </DialogActions>
      </Dialog>

: 

  <Dialog open={showLogIn} onClose={handleClose}>
  <DialogTitle>登录</DialogTitle>
  <DialogContent>
  <fieldset className="postitem">
    <legend>请输入邮件和密码</legend>
    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
    <form>

    <dl>

<dt>邮件:</dt>
<dd><input
            type="email"
            placeholder="请输入邮件"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
</dd>

<dt>密码:</dt>
<dd> <input
            type="password"
            placeholder="请输入密码"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
</dd>        
          
<dt></dt>
<dd>  
{/* <form onSubmit={handleSubmit}> */}
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LdG1qwiAAAAAKdUIqRYEB6e0uDzR848s05NS72x"
          
          // size="invisible"
        />
      {/* </form> */}
  
  {/* <input type="checkbox" checked={checked} onChange={handleChange} />  */}
</dd>        
</dl>   
      
        <button onClick={() => onLoginFormSubmit()} type="button">
          登录
        </button>
      </form>
</fieldset>
      </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>

}
</>

)
 };

export default LoginModal