import PostItem from './PostItem';
import 'draft-js/dist/Draft.css';
import draftToHtml from "draftjs-to-html";
import 'draft-js/dist/Draft.css';
import { Editor, EditorState, convertToRaw,convertFromRaw } from "draft-js";
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { memo } from "react";

   //https://medium.com/@blue.sin9k/managing-pop-ups-using-react-router-7ef10ef630a0
   //https://www.linkedin.com/pulse/how-create-private-protected-route-react-router-v6-bikash-sharma/?trk=articles_directory
   //npm install draft-js
   //https://medium.com/how-to-react/different-ways-to-loop-through-arrays-and-objects-in-react-39bcd870ccf
   import { domainURI } from "../config";
   import VideoItem from './VideoItem';
function MyPost(props)
{
  
  let [morePostList, setMorePostList] = useState([]);
  let [PostList, setPostList] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(5); 
  let [refresh, setRefresh] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
     
    const [secToken,setsecToken] = useState(() => {
      if (
        JSON.parse(localStorage.getItem('token'))
      ) {
        return JSON.parse(localStorage.getItem('token'));
      } else { return '' }
      }
    );
  
  console.log(props.userID+" my post"+secToken.Id); 

    const fetchMyPostData = useCallback(()=>{
      //domainURI + '/api/PostListViews?Refreshed='+refresh+'&pageNumber='+pageNumber+'&pageSize='+pageSize,{
      //console.log("refreshed e"+refresh)
      axios.get(domainURI+'/api/PostList/GetMyGongLueList?userID='+props.userID+'&Refreshed='+refresh+'&pageNumber='+pageNumber+'&pageSize='+pageSize,
        {
          headers: { 
            'Accept': 'application/json',
            'Access-Control-Allow-Credentials':true,
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Authorization': 'Bearer '+ secToken.Token 
           }, responseType: "json",
        } 
      ).then(response=>{
        //console.log("subdata"+JSON.stringify(response.data));
        setPostList(response.data)
        setMorePostList(morePostList => [...morePostList, ...response.data]);
      })
      .catch(error => {
        alert("Encountered an error with fetching and parsing data", error);
      }
    );
  },[refresh,props.userID,pageNumber]) 

  useEffect(() => {
    if(props.userID!=0)
       if(props.userID == secToken.Id)
       {
         fetchMyPostData();
       }
     
  }, [refresh,pageNumber,props.userID]);

  const RefreshState = () => {
    setRefresh((c) => c + 1);
    //fetchPostData();
    //window.location.reload();
    //console.log("refreshed"+refresh)
  };

  const onMoreButtonClick = () => {
    setPageNumber(pageNumber+1);
  }

  return(
    <>
    <div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "left" }} > 
    {
      morePostList.map((p) =>(p.FileType===0)?
      <PostItem key={p.ID} userID={props.userID} RefreshState={RefreshState} value={{ PostID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
      :
      <VideoItem key={p.ID} userID={props.userID}  RefreshState={RefreshState} value={{ VideoID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
     )
    }
    </div>
    <div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "center" }} >
    {
      (PostList.length<pageSize)? (props.userID===0? "": "No more..."): <button className="ovalbutton"  onClick={()=>onMoreButtonClick()}>More</button>
    }    
    { 
      props.userID===0 && <div className="fail">Uh oh... you need login or you don't have any posts</div> 
    }  
   </div>
     </>
  )
 
}

export default MyPost