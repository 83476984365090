
import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch, useNavigate,useParams
} from "react-router-dom";

import axios from "axios";
import { domainURI } from "../config";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';




const DeleteComment = (props) => {
    let { value } = useParams(); 
   
    

  const [secToken,setsecToken] = useState(() => {
    if (
      JSON.parse(localStorage.getItem('token'))
    ) {
      return JSON.parse(localStorage.getItem('token'));
    } else { return '' }
    }
  );
   
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    let navigate = useNavigate();


    const closeModal = () => {

        handleClose();
        //navigate('../MyPost');
        //window.location.reload();
    navigate(window.location)
      }

      const fetchDeleteData = useCallback(()=>{

        if(secToken)
        {
    
    axios
    .delete(
      domainURI+'/api/PostComments/'+value,{
         headers: { 
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': 'Bearer '+ secToken.Token 
      }, responseType: "json",
      }     
    )
     .then(response=>{
     //console.log("subdata"+JSON.stringify(response.data));
     // setPostList(response.data)
     //delete likes belong to this post
      axios.delete(domainURI+'/api/CommentLikes/'+value,{
         headers: { 
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials':true,
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': 'Bearer '+ secToken.Token 
          }, responseType: "json",
        }     
      )
     //delete pics belong to this post


     //delete comments
    })
    .catch(error => {
    console.log(
      "Encountered an error with fetching and parsing data",
      error
    );
    }
    );
    
    
    }else{
      //setErrorMessage("You need to login to delete your post.");
    }
        
    },[]) 
    
    
    useEffect(() => {
      fetchDeleteData()
    }, [Location.key]);

    useEffect(()=>{
        //localStorage.removeItem('token');
        setShow(true)
        },[])

    return (
      <>
       

       <Dialog open={show} onClose={handleClose}>
             <DialogContent>
Deleted.
    </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>关闭</Button>         
        </DialogActions>
      </Dialog>

      </>
    );
  
  
  };

  export default DeleteComment;