import axios from 'axios';
import React,{useState, useEffect} from 'react';
import { domainURI } from "../config";
const CurrentUserLoader=(props) =>{

//console.log(props.value)
const [user,setUser] = useState(null);
useEffect(()=>{
   // api/UsersAPI/5
props.userID&&(async()=>{
  //  const response=await axios.get('https://localhost:44340/odata/Users('+props.userID+')');
const response=await axios.get(domainURI+'/api/UsersAPI/'+props.userID);

setUser(response.data)

})();
},[]);

//console.log(JSON.stringify(user))

return (
<>
{
    React.Children.map(props.children,child =>{
if(React.isValidElement(child)) {
    return React.cloneElement(child,{user});
}
return child;

    })
}
</>
)

}
export default CurrentUserLoader;