
import React, { useRef, useEffect,  useState, Fragment } from "react";
import CommentPostDisplay from "./CommentPostDisplay";
import {
useParams
    } from "react-router-dom";
import CommentList from "./CommentList";
import axios from "axios";

import TextareaAutosize from '@mui/base/TextareaAutosize';
import { domainURI } from "../config";

const SubmitComment = (props) => {

  const [userid, setUserID] = useState(0);



  const [secToken,setsecToken] = useState(() => {
    if (
      JSON.parse(localStorage.getItem('token'))
    ) {
      return JSON.parse(localStorage.getItem('token'));
    } else { return '' }
    }
  );


  //const tokenString = localStorage.getItem('token');
  
  useEffect(() => {
     if(secToken)
  {
    //const userToken = JSON.parse(tokenString);
    setUserID(secToken.Id)
  } else{
    setUserID(0)

  }
  }, [secToken]);

  let { postID,postType,userID,CommentID } = useParams(); 
  let [PostList, setPostList] = useState([]);
  
  let [refresh, setRefresh] = useState([0]);

  const commentRef = useRef(null);

  // const [textAreaValue, setTextAreaValue] = useState(""); 


let onPostFormSubmit = (e) => {
  e.preventDefault();
  saveContent(commentRef.current.value);
  RefreshState();
 //console.log(refresh);
  
};

const [action, setAction] = useState('');
const [url, setURL] = useState('');
 
useEffect(() => {
      
   setURL(domainURI+'/api/PostComments')
    setAction('post'); 

}, [refresh]);

const saveContent = (content) => {   

  //console.log("comment savecontent")
  //alert("sdf")
  const response = axios({ method: action, url: url, data: {         
    "Comment":content
    ,"UserID":JSON.stringify(props.userID)
    ,"PostID":postID
    ,"ParentCommentID":CommentID
   ,"CommentType":postType
  }, headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer '+ secToken.Token 
  }}).then(function (response) {
   // alert("sdf")
   //console.log("save comment"+response.data);
   //need to refresh page when on my posts page
   //window.location.reload();
  })
  .catch(
    
    
    function (response) {
    //handle error
    //setErrorMessage("Something has happened.")
    alert(response)
   //console.log(response);
   
  }
  
  
  // error => {
  //   alert("出现错误", error);
  // }
  
  
  
  );
  
    }
  


const CommentForm = ({ onSubmit }) => {   
    return (
     

      <form onSubmit={onSubmit}>
        {/* <div className='wrapper-div'> */}
              
       <TextareaAutosize 
       ref = {commentRef}   width='75%'
       rows={4}
  maxRows={4}
  aria-label="Comment"
  placeholder="这里点评"
  defaultValue=""
  maxLength='200'
  max-width='580px'
  
/>


        <button  className="ovalbutton">提交</button> 
         {/* </div>   */}
      </form>
    );
    }

    const RefreshState = () => {
      setRefresh((c) => c + 1);
      //props.RefreshShowState();
      //fetchPostData();
      //window.location.reload();
      //console.log("refreshed"+refresh)
    };

return(
<>
<div style={{ backgroundColor:"silver", padding: "10px", fontSize: "16px", textAlign: "left" }} >
<CommentPostDisplay postID = { postID} postType={postType} userID={props.userID}></CommentPostDisplay>
 
 { userid===0 ?"登录才能点评": <CommentForm onSubmit={onPostFormSubmit} />} 
    <hr></hr> 
  <CommentList refresh={refresh} RefreshState={RefreshState} value={{ postID:postID,postType:postType,userID:props.userID}}/>
  </div>
</>

)
 };

export default SubmitComment