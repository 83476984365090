//https://www.codementor.io/@obabichev/react-token-auth-12os8txqo1
//https://www.sohamkamani.com/blog/javascript-localstorage-with-ttl-expiry/
import { useState } from 'react';

export const useToken = () => {
    const [token, setTokenInternal] = useState(() => {
        //return localStorage.getItem('token');

        const tokenString = localStorage.getItem('token');
             const userToken = JSON.parse(tokenString);
             console.log("userToken1"+userToken)
             return userToken


    });

    const setToken = newToken => {
        //localStorage.setItem('token', newToken);
        //setTokenInternal(newToken);
        // const now = new Date()
        // const item = {
        //     value: newToken,
        //     expiry: now.getTime() + 5000,
        // }

        if(newToken)
        {
            window.localStorage.setItem('token', JSON.stringify(newToken));
        setTokenInternal(newToken);
        }
       
        console.log("userToken2"+newToken.Id)
    }

    return [token, setToken];
}


// const getToken = async () => {
//     if (!_token) {
//         return null;
//     }

//     if (isExpired(getExpirationDate(_token.accessToken))) {
//         const updatedToken = await fetch('/update-token', {
//             method: 'POST',
//             body: _token.refreshToken
//         })
//             .then(r => r.json());

//         setToken(updatedToken);
//     }

//     return _token && _token.accessToken;
// };


// function getWithExpiry(key) {
// 	const itemStr = localStorage.getItem(key)
// 	// if the item doesn't exist, return null
// 	if (!itemStr) {
// 		return null
// 	}
// 	const item = JSON.parse(itemStr)
// 	const now = new Date()
// 	// compare the expiry time of the item with the current time
// 	if (now.getTime() > item.expiry) {
// 		// If the item is expired, delete the item from storage
// 		// and return null
// 		localStorage.removeItem(key)
// 		return null
// 	}
// 	return item.value
// }


// export default function useToken() {
//   const getToken = () => {
//     const tokenString = localStorage.getItem('token');
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
//   };

//   const [token, setToken] = useState(getToken());

//   const saveToken = userToken => {
//     localStorage.setItem('token', JSON.stringify(userToken));
//     setToken(userToken.token);
//   };

//   return {
//     setToken: saveToken,
//     token
//   }
// }