import React, { useEffect, useState, useCallback, Fragment } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PostEditor from "./PostEditor";
import VideoForm from "./VideoForm";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Navigate,
  Link,
  useRouteMatch,
  useNavigate
} from "react-router-dom";

//https://medium.com/@blue.sin9k/managing-pop-ups-using-react-router-7ef10ef630a0
//https://www.linkedin.com/pulse/how-create-private-protected-route-react-router-v6-bikash-sharma/?trk=articles_directory
//npm install draft-js
//https://medium.com/how-to-react/different-ways-to-loop-through-arrays-and-objects-in-react-39bcd870ccf
import { domainURI } from "../config";
import VideoItem from './VideoItem';

function CreatePost(props) {

  let onPostFormSubmit = (e) => {
    // Navigate("/MyPost");
  };

  const [selectedOption, setSelectedOption] = useState('Text');

  const onValueChange = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
    setSelectedOption(event.target.value);
    //console.log("radio post type"+event.target.value+props.userID)

  }

  return (
    <>
      {props.userID === 0 && "发帖需要登陆"}
      {props.userID != 0 && 
      
      <form onSubmit={onPostFormSubmit}>

        <fieldset className="postEditor">
          <legend>发帖</legend>

          <dl>

            <dt>帖类:</dt>
            <dd>
            &nbsp;&nbsp;
                <input type="radio" value="Video" onChange={onValueChange} defaultChecked={selectedOption === 'Video'} name="PostType" /> 视频
                &nbsp;<input type="radio" value="Text" onChange={onValueChange} defaultChecked={selectedOption === 'Text'} name="PostType" />文本/图片
              
            </dd>
         
          </dl>
          {selectedOption === 'Text' ? <PostEditor userID={props.userID} /> : <VideoForm userID={props.userID} />}

        </fieldset>
       </form>
      }
    </>
  )

};

export default CreatePost