
import React, {  useEffect,  useState, Fragment } from "react"; 
import LikeButton from "./LikeButton";
import DeleteVideoButton from "./DeleteVideoButton";
import PostCommentButton from "./PostCommentButton";
import axios from 'axios';
import FollowButton from "./FollowButton";
import { domainURI } from "../config";
const VideoItem = ({ userID, RefreshState, value}) => {
  const date = new Date();
  const offsetInHours = date.getTimezoneOffset() / 60; 
  const subtractHours=(date, hours)=> {
    date.setHours(date.getHours() - hours);
  
    return date;
  }
 
  let [showCommentList, setShowCommentList] = useState(false);
  let [refreshCount, setRefreshCount] = useState(false);

//console.log("postItem"+userID)

const RefreshShowCommentListState = () => {
  
  setShowCommentList(!showCommentList);
};

// const RefreshShowState = () => {
//   setRefreshCount(!refreshCount)
//   setShowCommentList(true);

// };

const [user,setUser] = useState(null);
useEffect(()=>{
   // api/UsersAPI/5
   value.UserID&&(async()=>{
  //  const response=await axios.get('https://localhost:44340/odata/Users('+props.userID+')');
const response=await axios.get(domainURI+'/api/UsersAPI/'+value.UserID);

setUser(response.data)
//console.log(response.data)

})(value.UserID);
},[value.UserID]);
const { hobbies, ID,Email,Password,LastName,FirstName,DisplayName,UserName,EmailVerified,Bio,Location,WebSite,WeChat,FaceBook,Telegram}=user||{};

return(
        <>        
       
     
  <fieldset className="postitem">
  <legend> 
    <a  className="text-align:right; float:right;text-decoration:none;"  href={"/UserPost/"+value.UserID }>
        {DisplayName}&nbsp;
        <img className='avatar' src={domainURI+"/api/photos/"+value.UserID} alt="avatar"   onError={e => {
          e.currentTarget.src = domainURI+"/api/photos/10"
        }} /> 
    </a>&nbsp;
    {value.PostLocation}
    &nbsp;发布于&nbsp;
    {
      //  new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',hour: 'numeric',  minute: 'numeric', second: 'numeric'}).format(subtractHours(new Date(value.PostedDate), offsetInHours))
      subtractHours(new Date(value.PostedDate), offsetInHours).toLocaleString()
    }
 
    {value.UserID!==userID && <FollowButton authorID={value.UserID} userID={userID}></FollowButton>}
  </legend>
  <div className="video-wrapper">
     <video  controls disablePictureInPicture controlsList="nodownload" width='100%' height='100%' autoPlay loop muted  preload="metadata" playsInline>          
     <source src={"https://s3.amazonaws.com/travbar.com/"+value.UserID+"/video/"+value.VideoID+value.Text}  key={value.VideoID} />        
     Sorry, your browser doesn't support embedded videos.
    </video>   
   
  </div>
  <nav>      
  {                 
     value.UserID===userID ? <DeleteVideoButton   userID={userID} RefreshState={RefreshState} postID={value.VideoID}></DeleteVideoButton> : null
  }              
  <LikeButton userID={userID}  postID={value.VideoID} postType="true"></LikeButton>
  <PostCommentButton refreshCount={refreshCount} RefreshShowCommentListState={RefreshShowCommentListState}  userID={userID}  RefreshState={RefreshState}  postType="true" postID={value.VideoID}/>              
          
      </nav>
      </fieldset>           
  
      </>
    )
  };
   
export default VideoItem