import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
  DefaultDraftBlockRenderMap,
  convertFromRaw,
  AtomicBlockUtils
} from "draft-js";
import { maxlength } from "../config";
import { Editor } from "react-draft-wysiwyg";
// import Editor from "./WysiwygEditor.jsx";
import Immutable from "immutable";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
import ColorPic from "./ColorPic";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { domainURI,REACT_APP_BUCKET_NAME,REACT_APP_REGION, REACT_APP_ACCESS,REACT_APP_SECRET} from "../config";
import AWS from 'aws-sdk';

const S3_BUCKET =REACT_APP_BUCKET_NAME;
const REGION =REACT_APP_REGION;


AWS.config.update({
    accessKeyId: REACT_APP_ACCESS,
    secretAccessKey: REACT_APP_SECRET
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})
// https://github.com/jpuri/react-draft-wysiwyg/issues/646
//https://codesandbox.io/s/50pp3xlv24?file=/MyEditor.js:6273-6773
//https://stackoverflow.com/questions/47604432/how-to-insert-upload-image-update-entity-and-blocks-in-draft-js/47614259#47614259
class CustomOption extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object
  };

  addStar = () => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      "⭐",
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  render() {
    return (
      <div
        className="rdw-option-wrapper"
        aria-selected="false"
        role="button"
        title="Star"
        onClick={this.addStar}
      >
        *
      </div>
    );
  }
}
class CustomTable extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object
  };

  addTable = () => {
    let { editorState, onChange } = this.props;
    let contentState;
    // contentState = Modifier.replaceText(
    //   editorState.getCurrentContent(),
    //   editorState.getSelection(),
    //   "TTT",
    //   editorState.getCurrentInlineStyle()
    // );

    let html = `<table>
        <tr>
          <td></td>
          <td><em>B1</em></td>
        </tr>
        <tr>
          <td>A2</td>
          <td>B2</td>
        </tr>
      </table>`;

    // const blocksFromHTML = htmlToDraft(html);
    const blocksFromHTML = convertFromHTML(html);
    const { contentBlocks, entityMap } = blocksFromHTML;
    contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      contentState.getBlockMap()
    );

    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  render() {
    return (
      <div
        className="rdw-option-wrapper"
        aria-selected="false"
        title="Table"
        onClick={this.addTable}
      >
        T
      </div>
    );
  }
}

  const PostEditor= (props) => {
  

    const [secToken,setsecToken] = useState(() => {
      if (
        JSON.parse(localStorage.getItem('token'))
      ) {
        return JSON.parse(localStorage.getItem('token'));
      } else { return '' }
      }
    );


    //const initialFileState = [];
    const [fileArray, setFileArray] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    //const [postLocation, setPostLocation] = useState("");
    //console.log(props.postID)
    const [max, setMax] = useState(maxlength);
    const [published, setPublished] = useState(false);
    const editorRef = useRef(null);
    const [postLocation, setPostLocation] = useState("");
    //console.log(props.postID+"props Post Editor"+JSON.stringify(props.userID))    
    //const [max, setMax] = useState(10);
    const MAX_LENGTH = max;
   
    //const [userid, setUserID] = useState(props.userID);  
    //const content = window.localStorage.getItem('content');

    const currentCount = editorState.getCurrentContent().getPlainText().length;

    const fetchPostData = useCallback(()=>{

    const response = axios
    .get(
      domainURI+'/odata/GongLuePosts/('+props.postID+')',{
         headers: { 
          'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': 'Bearer '+ secToken.Token 
      }, responseType: "json",
      }
    
    )
    .then(function (response) {
      //console.log("data"+JSON.stringify(response.data.Text));
      const content = response.data.Text;
      setPostLocation(response.data.Location);
      setPublished(response.data.Published)
      //console.log("loaded"+JSON.stringify(content))
      if (content) {
       
       setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(content))));
       
      } else {
        //this.state.editorState = EditorState.createEmpty();
      }  
      })
      .catch(function (response) {
        //handle error
        //setErrorMessage("Something has happened.")
        //console.log(response);
      });

   },[]) 


 
    useEffect(() => {
        if(props.postID)
        {
            fetchPostData()
        }    
        
        
  if(props.postID)
  {
    setAction('patch');
    setURL(domainURI+'/odata/GongLuePosts('+props.postID+')')
  }else{
   setURL(domainURI+'/odata/GongLuePosts')
    setAction('post'); 
  }

}, []);

const [action, setAction] = useState('');
const [url, setURL] = useState('');

const saveContent = (content,published) => {
   
//window.localStorage.setItem('content', JSON.stringify(convertToRaw(content)));
//console.log("userToken.Id"+userid)
//console.log("url"+url)

const response = 
axios({ method: action, url: url, data: {         
  "Text":JSON.stringify(convertToRaw(content)),
  "UserID":JSON.stringify(props.userID),
  "Location":postLocation,
  "Published":published

}, headers: {
  'Content-Type': 'application/json; charset=utf-8',
  'Authorization': 'Bearer '+ secToken.Token 
}}).then(function (response) {
  //console.log(JSON.stringify(response.data.value))
  // initialFileState.forEach((fileid) => {
  //  console.log("file ID"+fileid)})
  //console.log(JSON.stringify(fileArray))
  //fileArray.map(function(fileid) {
  // console.log(JSON.stringify(fileid))

  axios(
    { method: 'put', url: domainURI+'/api/Pictures/'+response.data.value, 
  data: JSON.stringify(fileArray)
, headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer '+ secToken.Token 
  }}
  
  ).then(function (response){

  //});
  //api/Pictures/5
  
});

  //console.log("save"+response.data);
  //need to refresh page when on my posts page
})
.catch(function (response) {
  //handle error
  //setErrorMessage("Something has happened.")
 //console.log(response);
});




  }
  

  const onSaveButtonClick = (event) => {
    //event.preventDefault();
    if(postLocation)
    {
const contentState = editorState.getCurrentContent();
    saveContent(contentState,false);

    alert("储存成功。")

    }else{
      //event.preventDefault();
      alert("需要填写主题.")
    }  

    //console.log(contentState)
    window.location.reload();
  }

  const onPublishButtonClick = (event) => {
    //event.preventDefault();
    if(postLocation)
    {
    const contentState = editorState.getCurrentContent();
    saveContent(contentState,true);
    alert("恭喜成功发布。")
    }else{

      //event.preventDefault();
      alert("需要填写主题.")

    }
    //console.log(contentState)
    window.location.reload();
  }

  const onEditorStateChange = editorState => {
  
  const MAX_LENGTH = max;
  const length = editorState.getCurrentContent().getPlainText('').length;

  if (length <= MAX_LENGTH) {
    setEditorState(editorState);
  }
  else
 {
  window.alert(`Sorry, you've exceeded your limit of ${MAX_LENGTH}`)
 }

    // this.setState({
    //   editorState
    // });
  };


  const uploadImageCallBack = (file)=> {   
    return new Promise((resolve, reject) => {
     //console.log(file.size)
      if (file.size > 10e6) {
        window.alert("Please upload a file smaller than 10 MB"+file.size);
        return false;
      }
      
      axios(
        { method: 'POST', url:domainURI+"/api/Pictures/", 
        data: {
          "FileName":file.name,"FileSize":file.size,"PostID":0,"FileType":file.type,"UserID":props.userID,"FileExtension":file.name.split('.').pop()
        }
      , headers: {
          'Accept': 'application/json',
          // 'Access-Control-Allow-Credentials':true,
          'Access-Control-Allow-Origin' : 'https://www.travbar.com,http://www.travbar.com,http://localhost:3000',
          'Content-Type': 'application/json; charset=utf-8',
          'Authorization': 'Bearer '+ secToken.Token 
        }}

       ).then(responseImage => {
       // console.log("pic"+responseImage.data)
        let tempArray = fileArray;
        let tempItem =   responseImage.data;

        //uploadFile(file,tempItem); 

        const params = {
          ACL: 'public-read',
          Body: file,
          Bucket: S3_BUCKET,
          Key:props.userID+'/pictures/'+tempItem+file.name 
      };
    
      myBucket.putObject(params)
          .on('httpUploadProgress', (evt) => {
              
             if(evt.loaded===evt.total && evt.loaded!==0)
             {
                 //publishSubmitForm();
                 //alert("上传成功。");
           
                 //const url ="https://s3.amazonaws.com/travbar.com/"+props.userID+'/pictures/'+tempItem+file.name;
                 //alert(exists(url));
                     //await setTimeout(5000);
                 
                 //    setTimeout(resolve({ data: { link: url } }), 5000);
                 
                           
                   
             }           
          })
          .send((err) => {
              if (err) 
           {
             alert("上传出错请稍后再试。"+err);
           }  
          else
          {
            var url = myBucket.getSignedUrl('getObject', {Key: params.Key});           
            setTimeout(resolve({ data: { link: url.split('?')[0] } }), 5000);
            console.log(url);
          }   
        })       
        tempArray.push(tempItem);
        setFileArray(tempArray)
       })



      
           })
    };
    // https://github.com/aws/aws-sdk-js/commit/084f676927b9cd3da337bd6d0d230680c138d73b
 function exists(url) {
      axios.get(
  url,{
     headers: { 
      'Accept': 'application/json',
      'Access-Control-Allow-Credentials':true,
     'Access-Control-Allow-Origin' : 'https://travbar.com,https://www.travbar.com,http://localhost:3000',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }, responseType: "json",
  }
 
)
 .then(response=>{
 console.log("subdata"+JSON.stringify(response.data));

})

    }
  
const uploadFile = (file,picid) => {
  //setFileKey(userid+'/'+file.name);
  const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key:props.userID+'/pictures/'+picid+file.name 
  };

  myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
          
         if(evt.loaded===evt.total && evt.loaded!==0)
         {
             //publishSubmitForm();
             //alert("上传成功。");
         }           
      })
      .send((err) => {
          if (err) alert("上传出错请稍后再试。"+err);
      })
};

// const saveIntoDB = (file)=>{

//            axios(
//             { method: 'POST', url:domainURI+"/api/Pictures/", 
//             data: {
//               "FileName":file.name,"FileSize":file.size,"PostID":0,"FileType":file.type,"UserID":userid,"FileExtension":file.name.split('.').pop()
//             }
//           , headers: {
//               'Accept': 'application/json',
//               // 'Access-Control-Allow-Credentials':true,
//               'Access-Control-Allow-Origin' : 'https://www.travbar.com,http://www.travbar.com,http://localhost:3000',
//               'Content-Type': 'application/json; charset=utf-8',
//               'Authorization': 'Bearer '+ secToken.Token 
//             }}

//            ).then(responseImage => {
//            // console.log("pic"+responseImage.data)
//             let tempArray = fileArray;
//             let tempItem =   responseImage.data;

//             uploadFile(file,tempItem);
//             tempArray.push(tempItem);
//             setFileArray(tempArray)
//            })

// };



  const blockRenderMap = Immutable.Map({
    section: {
      element: "section"
    },
    table: {
      element: "table"
    },
    tr: {
      element: "tr"
    },
    td: {
      element: "td"
    },
    th: {
      element: "th"
    }
  });


  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
    blockRenderMap
  );



//handle extra text

const getLengthOfSelectedText = () => {
  const currentSelection = editorState.getSelection();
  const isCollapsed = currentSelection.isCollapsed();

  let length = 0;

  if (!isCollapsed) {
    const currentContent = editorState.getCurrentContent();
    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const startBlock = currentContent.getBlockForKey(startKey);
    const isStartAndEndBlockAreTheSame = startKey === endKey;
    const startBlockTextLength = startBlock.getLength();
    const startSelectedTextLength =
      startBlockTextLength - currentSelection.getStartOffset();
    const endSelectedTextLength = currentSelection.getEndOffset();
    const keyAfterEnd = currentContent.getKeyAfter(endKey);
    if (isStartAndEndBlockAreTheSame) {
      length +=
        currentSelection.getEndOffset() - currentSelection.getStartOffset();
    } else {
      let currentKey = startKey;

      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          length += startSelectedTextLength + 1;
        } else if (currentKey === endKey) {
          length += endSelectedTextLength;
        } else {
          length += currentContent.getBlockForKey(currentKey).getLength() + 1;
        }

        currentKey = currentContent.getKeyAfter(currentKey);
      }
    }
  }

  return length;
};

const handleBeforeInput = () => {
  const currentContent = editorState.getCurrentContent();
  const currentContentLength = currentContent.getPlainText("").length;
  const selectedTextLength = getLengthOfSelectedText();

  if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
    console.log("you can type max ten characters");

    return "handled";
  }
  return "not-handled";
};

const removeSelection = () => {
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const startOffset = selection.getStartOffset();
  const endKey = selection.getEndKey();
  const endOffset = selection.getEndOffset();
  if (startKey !== endKey || startOffset !== endOffset) {
    const newContent = Modifier.removeRange(
      editorState.getCurrentContent(),
      selection,
      "forward"
    );
 const tempEditorState = EditorState.push(
      editorState,
      newContent,
      "remove-range"
    );
    setEditorState(tempEditorState);
    return tempEditorState;
  }
  return editorState;
};

const addPastedContent = (input, editorState) => {
  const inputLength = editorState.getCurrentContent().getPlainText().length;
  let remainingLength = MAX_LENGTH - inputLength;

  const newContent = Modifier.insertText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    input.slice(0, remainingLength)
  );
  this.setEditorState(
    EditorState.push(editorState, newContent, "insert-characters")
  );
};
const handlePastedText = (pastedText) => {
  const currentContent = editorState.getCurrentContent();
  const currentContentLength = currentContent.getPlainText("").length;
  const selectedTextLength = getLengthOfSelectedText();

  if (
    currentContentLength + pastedText.length - selectedTextLength >
    MAX_LENGTH
  ) {
    const selection = editorState.getSelection();
    const isCollapsed = selection.isCollapsed();
    const tempEditorState = !isCollapsed ? removeSelection() : editorState;
    this.addPastedContent(pastedText, tempEditorState);

    return "handled";
  }
  return "not-handled";
};

const styles = {
  border: '1px solid rgba(0, 0, 0, 0.05)', 
};
//end of handle extra text


// const [selectedOption, setSelectedOption] = useState('');

// const onValueChange=(event) =>{
//   setSelectedOption(event.target.value);
//   console.log("radio post type"+event.target.value)
// }

    return (
      <>
      {/* { props.userID!=0&& <label>Post Type: &nbsp;<div onChange={onValueChange.bind(this)}>
        <input type="radio" value="Video" name="PostType"/> Video
        &nbsp;<input type="radio" value="Text" name="PostType"/> Text/Pictures
      </div></label> }       */}
      {props.userID!==0?
      <>
<dl>
         <dt>主题:</dt>  
<dd><input type="text" maxLength="50" size="40" value={postLocation} onChange={(e) => setPostLocation(e.target.value)}  required />
</dd>
</dl>

      <Editor
       ref={editorRef}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        toolbarCustomButtons={[<CustomOption />, <CustomTable />]}
        onEditorStateChange={onEditorStateChange}
        blockRenderMap={extendedBlockRenderMap}      
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        hashtag={{
          separator: ' ',
          trigger: '#',
        }}
        toolbar={{
          // colorPicker: { component: ColorPic },https://segmentfault.com/q/1010000015473209
          textAlign: { inDropdown: true },
          fontFamily: { options: ['宋体', '黑体', '楷体', '微软雅黑','Arial',  'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana',]},
          inline: { inDropdown: true },
          list: { inDropdown: true },
          image: {
            
            uploadCallback: uploadImageCallBack,
            alignmentEnabled:false,
            uploadEnabled: true,
            // uploadCallback: this.uploadCallback,
            previewImage: true,
            //inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
                 height: '80%',
                 width: '80%',
            },
            
            height: '80%',
            width: '80%',
           
          }          
        }}
        localization={{
          locale: 'zh',
        }}
      /> </>
      :"需要登陆发帖"}
      
    {/* { props.userID!=0&& <label>Location:<br></br><input type="text" value={postLocation} onChange={(e) => setPostLocation(e.target.value)}/></label> }  */}

    { 
    props.userID!==0&& <div style={styles}>{currentCount}/{MAX_LENGTH}字节</div>
    }

    {(!postLocation || currentCount<1) && <span style={{color:'red'}}>(需要填写主题和正文才能存档或发布)</span>}

    {(props.userID!==0 && !published) && <button type="submit"  disabled={(!postLocation || currentCount<1)} className="uploadbutton"  onClick={()=>onSaveButtonClick()}>存档</button>}

    {props.userID!==0 && <button type="submit" disabled={(!postLocation || currentCount<1)} className="uploadbutton"  onClick={()=>onPublishButtonClick()}>发布</button>}
      
      </>  
    
    );  
}

export default PostEditor;
