import 'draft-js/dist/Draft.css';
import draftToHtml from "draftjs-to-html";
import 'draft-js/dist/Draft.css';
// import { Editor, EditorState, convertToRaw,convertFromRaw } from "draft-js";
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
// import { memo } from "react";
// import PostItem from './PostItem';
// import VideoItem from './VideoItem';
import { domainURI } from "../config";

const CommentPostDisplay=(props)=>{

 const [url, setURL] = useState(props.postType==='false'?domainURI+'/odata/GongLuePosts/('+props.postID+')':domainURI+'/api/PostListViews/'+props.postID+'?fileType=1');
  // setURL('https://localhost:44340/api/PostComments')
let [post, setPost] = useState([]);

let [videoUrl, setVideoUrl] = useState([]);
let [postDateTime, setPostDateTime] = useState([]);
let [refresh, setRefresh] = useState([0]);
const [ftype, setFileType] = useState(props.postType==='false'?0:1)
const [postValues, setPostValues] = useState({
  postID: '', 
  postDateTime: '',
  userID: '',
  location: '',
  displayName:'',
  text:''
});

if(props.postID===0)
{

}
//console.log("posttype"+props.postType);
const fetchPostData = ()=>{

  const response = axios
  .get(domainURI+'/api/PostListViews/'+props.postID+'?fileType='+ftype    
    ,{
       headers: { 
        'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }, responseType: "json",
    }
  
  )
  .then(function (response) {

    console.log("data"+JSON.stringify(response));
   
    
  
setPostDateTime(response.data.PostDateTime)
 
setPostValues(
    {...postValues, 
      postID:response.data.ID,
      userID:response.data.UserID,  
      postDateTime:response.data.PostDateTime,
      location:response.data.Location,
      displayName:response.data.displayName,
  text:response.data.Text
    })
  
    if(props.postType==='false')
    {
      const content = draftToHtml(JSON.parse(response.data.Text));
    setPost(content);
    //console.log("loaded"+content)  
    }else{
      const videourl ="https://s3.amazonaws.com/travbar.com/"+response.data.UserID+"/video/"+props.postID+response.data.Text;
      setVideoUrl(videourl);
    }

  //console.log("loaded"+post)
    })
    .catch(function (response) {
      //handle error
      //setErrorMessage("Something has happened.")
      //console.log(response);
    });

 } 


 useEffect(() => {
  
    fetchPostData();
    
},[props.postID]);

const RefreshState = () => {
  setRefresh((c) => c + 1);
  //fetchPostData();
  //window.location.reload();
  //console.log("refreshed"+refresh)
};

return(
  <>
  <div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "left" }} >
    <fieldset className="postitem">
      <legend>
      <a  className="text-align:right; float:right;text-decoration:none;"  href={"/UserPost/"+postValues.userID }>
         {postValues.DisplayName}&nbsp;<img className='avatar' src={domainURI+"/api/photos/"+postValues.userID} alt="avatar"   onError={e => {
          e.currentTarget.src = domainURI+"/api/photos/10"
        }} />
        </a> 
        &nbsp;
         {postValues.location}: 发布于
       {postValues.postDateTime}
        {/* {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',hour: 'numeric',  minute: 'numeric', second: 'numeric'}).format(new Date(postDateTime))} */}
      </legend>
  
      { props.postType==='false'?
      <div  className="EditorDiv" dangerouslySetInnerHTML={{ __html: post }} /> :
      (<div className="video-wrapper"><video  controls disablePictureInPicture controlsList="nodownload" width='100%' height='100%' autoPlay loop muted  preload="metadata" playsInline>          
      <source src={videoUrl}  key={props.postID} />        
      Sorry, your browser doesn't support embedded videos.
      </video></div>)   
    }
</fieldset>


    </div>     
  </>
  )
}
export default CommentPostDisplay