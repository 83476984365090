
import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react"; 
import axios from "axios";
//import SubmitComment from './SubmitComment';
import { Delete } from "@mui/icons-material";
import CommentEditor from "./CommentEditor";
import CommentFeedBackList from './CommentFeedBackList';
import TextareaAutosize from '@mui/base/TextareaAutosize';
//const AVATAR = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';
import { domainURI } from "../config";
const CommentItem = ({RefreshState,value}) => {
// const tokenString = localStorage.getItem('token');
// const userToken = JSON.parse(tokenString);
// const userid=userToken.Id;

const [userid, setUserID] = useState(0);

  // const tokenString = localStorage.getItem('token');
  
  // useEffect(() => {
  //    if(tokenString)
  // {
  //   const userToken = JSON.parse(tokenString);
  //   setUserID(userToken.Id)
  // } else{
  //   setUserID(0)

  // }
  // }, []);



  const [secToken,setsecToken] = useState(() => {
    if (
      JSON.parse(localStorage.getItem('token'))
    ) {
      return JSON.parse(localStorage.getItem('token'));
    } else { return '' }
    }
  );


  //const tokenString = localStorage.getItem('token');
  
  useEffect(() => {
     if(secToken)
  {
    //const userToken = JSON.parse(tokenString);
    setUserID(secToken.Id)
  } else{
    setUserID(0)

  }
  }, [secToken]);



//console.log(props.value.UserID==userid)

const [ count, setCount ] = useState(0);
const [ selfCount, setSelfCount ] = useState(0);
const [ btnColor, setbtnColor ] = useState();
const [postCommentCount, setPostCommentCount] = useState();
const [showReply, setShowReply] = useState(true);
  
let [refresh, setRefresh] = useState([0]);
const commentRef = useRef(null);
const [action, setAction] = useState('');
const [url, setURL] = useState('');

useEffect(() => {
      
   setURL(domainURI+'/api/PostComments')
    setAction('post'); 

}, [refresh]);

const saveContent = (content) => {   

  //console.log("comment savecontent")
  //alert("sdf")
  const response = axios({ method: action, url: url, data: {         
    "Comment":content
    ,"UserID":JSON.stringify(userid)
    ,"PostID":value.postID
    ,"ParentCommentID":value.CommentID
   ,"CommentType":value.postType
  }, headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer '+ secToken.Token 
  }}).then(function (response) {
   // alert("sdf")
   //console.log("save comment"+response.data);
   //need to refresh page when on my posts page
  })
  .catch(
    
    
    function (response) {
    //handle error
    //setErrorMessage("Something has happened.")
    alert(response)
   //console.log(response);
   
  }
  
  
  // error => {
  //   alert("Encountered an error with fetching and parsing data", error);
  // }
  
  
  
  );
  
    }
  



const CommentForm = ({ onSubmit }) => {   


  return (
   

    <form onSubmit={onSubmit}>
{/* <div className='wrapper-div' style={{ backgroundColor:"AliceBlue",paddingLeft: "80px", fontSize: "17px", textAlign: "center",overflowX:"auto", overflowY:"scroll" }}> */}
{/* <textarea
        ref = {commentRef}
          rows={2}
          width='75%'
          maxLength='200'
          wrap="hard"
          max-width='580px'
        />   */}
         <TextareaAutosize 
       ref = {commentRef}   width='75%'
       rows={2}
  maxRows={4}
  aria-label="Comment"
  placeholder="这里点评"
  defaultValue=""
  maxLength='200'
  max-width='580px'
  min-width='500px'
  
/>
        <button  className="ovalbutton">提交</button>   

{/* </div> */}
     
    
    </form>
  );
  }



  
const fetchPostData = useCallback(()=>{
  const response = axios({ method: 'get', url: domainURI+'/api/Commentlikes/'+value.CommentID+'/'+userid,
  data: JSON.stringify(userid)
, headers: {
  'Access-Control-Allow-Credentials':true,
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json; charset=utf-8'
  }, responseType: "json"}
  ).then(function (response) {
       //console.log("data"+JSON.stringify(response.data));
      const content = response.data;
      setCount(content.Cnt)
      //console.log("selfme"+content.MeCnt)
      setSelfCount(content.MeCnt)
      //console.log("self"+selfCount)
      if(content.MeCnt>0)
      {
        setbtnColor("red");
        //console.log("red")
      }else{
        setbtnColor("green");
        //console.log("green")
      }
      //console.log("loaded"+content)
      if (content) {
          //setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(content))));
      } else {
          //this.state.editorState = EditorState.createEmpty();
      }  
   })
   .catch(function (response) {
          //handle error
          //setErrorMessage("Something has happened.")
         console.log(response);
        });
      },[refresh]) 



  
const fetchCommentCountData = useCallback(()=>{
  
  const response = axios({ method: 'get', url: domainURI+'/api/PostCommentCount/'+value.CommentID,
   headers: {
  'Access-Control-Allow-Credentials':true,
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json; charset=utf-8'
  }, responseType: "json"})
  .then(function (response) {
       //console.log("data"+JSON.stringify(response.data));
      const content = response.data;
      setPostCommentCount(content)
      
   })
   .catch(function (response) {
        
         //console.log(response);
        });
      },[refresh]) 



      useEffect(() => {
        fetchPostData();
        fetchCommentCountData();
      }, [refresh]);
   


 
      const deleteComment =(a)=> {

        if(userid)
        {
    
    axios
    .delete(
      domainURI+'/api/PostComments/'+value.CommentID,{
         headers: { 
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Authorization': 'Bearer '+ secToken.Token 
      }, responseType: "json",
      }     
    )
     .then(response=>{
      // RefreshCommentItemState();
      // RefreshState();
      // setRefresh(refresh+1);

RefreshCommentItemState();
    setRefresh((c) => c + 1);

    RefreshState();


      axios.delete(domainURI+'/api/CommentLikes/'+value.CommentID,{
         headers: { 
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials':true,
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': 'Bearer '+ secToken.Token 
          }, responseType: "json",
        }     
      )
    })
    .catch(error => {
    console.log(
      "Encountered an error with fetching and parsing data",
      error
    );
    }
    );
    
    
    }else{
      //setErrorMessage("You need to login to delete your post.");
    }
     window.location.reload();
    } 
const shoot = (a) => {
  if(userid!=0)
  {
     if(selfCount>0)
    {       
      setCount(a-1);
      setSelfCount(0);
      setbtnColor("green");
      console.log(selfCount)
      const response = axios
      .delete(
        domainURI+'/api/CommentLikes/'+value.CommentID+'/'+userid,{
           headers: { 
            'Access-Control-Allow-Credentials':true,
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Authorization': 'Bearer '+ secToken.Token 
        }, responseType: "json",
        }      
      )    
    }else{

            setCount(a+1);
            setSelfCount(1);
            setbtnColor("red");

            const response = 
            axios({ method: 'post', url: domainURI+'/api/CommentLikes', data: {         
              "UserID":JSON.stringify(userid),
              "Likes":"true",
              "CommentID":JSON.stringify(value.CommentID)

            }, headers: {
              'Content-Type': 'application/json; charset=utf-8',
              'Authorization': 'Bearer '+ secToken.Token 
            }}).then(function (response) {
                        
              //console.log(response.data);
            })
            .catch(function (response) {
              //handle error
              //setErrorMessage("Something has happened.")
             //console.log(response);
            });

    }
  } 
    //setbtnColor((btnColor) => (btnColor === "red" ? "green" : "red"));
  }
 

  const RefreshCommentItemState = () => {
    setRefresh((c) => c + 1);
    //props.RefreshShowState();
    //fetchPostData();
    //window.location.reload();
    //console.log("refreshed"+refresh)
  };

  let onPostFormSubmit = (e) => {
    e.preventDefault();
    saveContent(commentRef.current.value);
    RefreshCommentItemState();
    setRefresh((c) => c + 1);

    RefreshState();
    //setRefresh(refresh+1)    
    setShowReply(true);
    window.location.reload();
    //props.RefreshShowState();    
  };

  
let CommentReply=(e)=>{
  setShowReply(e)
}
    return(
        <React.Fragment>
         
          <div  className="EditorDiv" style={{whiteSpace: "pre-wrap"}} key={value.CommentID} dangerouslySetInnerHTML={{ __html: value.Text }} />
           <nav>                               
           <button  className="ovalbutton" onClick={() => (userid === undefined || userid === null||userid===0)?alert("点赞需登录"):shoot(count)}>点赞({count})</button>   
             {userid===value.UserID&& <button className="ovalbutton"  onClick={()=>deleteComment()}>删除</button>}  
               <button  className="ovalbutton" onClick={()=>CommentReply(!showReply)}>点评({postCommentCount})</button>
           </nav>
         
           <hr></hr>
           {userid!==0 && <CommentForm onSubmit={onPostFormSubmit} />}
          {showReply&&<CommentFeedBackList refresh={refresh} RefreshCommentItemState={RefreshCommentItemState} CommentID={value.CommentID}/>} 
       </React.Fragment>
       )
        };
       
       export default CommentItem