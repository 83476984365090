
import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react"; 
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router'
import CommentEditor from "./CommentEditor";
import SubmitComment from "./SubmitComment";
import { domainURI } from "../config";
const PostCommentButton = ({refreshCount,RefreshState,userID,postID,postType,RefreshShowCommentListState}) => {
  
const [postCommentCount, setPostCommentCount] = useState();
let navigate = useNavigate();
const [action, setAction] = useState('');
const [url, setURL] = useState('');
 
useEffect(() => {
      
   setURL(domainURI+'/api/PostComments')
    setAction('post'); 

}, []);


const fetchCommentCountData = useCallback(()=>{
  
  const response = axios({ method: 'get', url:  domainURI+'/api/PostCommentCount/'+postID+"?postType="+postType,
   headers: {
  'Access-Control-Allow-Credentials':true,
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json; charset=utf-8'
  }, responseType: "json"})
  .then(function (response) {
       //console.log("data"+JSON.stringify(response.data));
      const content = response.data;
      setPostCommentCount(content)
      
   })
   .catch(function (response) {
        
         //console.log(response);
        });
      },[refreshCount]) 


      useEffect(() => {
       
        fetchCommentCountData();
      }, [refreshCount]);
      
      //postID/:postType/:userID
      const ShowPostCommentList =(a)=> {
      navigate("/PostComments/"+postID +"/"+postType+"/"+userID+"/0", { replace: false });
        //RefreshShowCommentListState();
      }

     
return(
        <>       
      
       <button className="ovalbutton" onClick={()=>ShowPostCommentList()}>点评({postCommentCount})</button>
  
   </>
    )
  };
   
export default PostCommentButton