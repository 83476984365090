import React from 'react';

function Card(props){
    return(
        <div style={{
            ...styles.card,
        backgroundColor:props.color,
        left:`${props.x}px`,
        top:`${props.y}px`,
        zIndex:props.z_index,
  transform:`translate(-50%,-50%) scale(${props.scale})`
  }} className="card">
<img style={styles.image} src={props.picsum_imag} alt=''></img>

        </div>
    )
}

const styles={
    card: {
        // position:'absolute',
        // top:'50%',
        // left:'50%',
        // transform:'translate(-50%,-50%)',
        // height:'800px',
        // width:'1200px',
        // border:'2px solid black',
        // boxSizing:'border-box',
        // backgroundColor:'red'
        margin:0,
        padding:0,
        width:'inherit',
        height:'inherit',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%,-50%)',
    },
    image: {
// width:'100%',
// height:'100%',
// boxShadow: '10px 10px 8px black'


maxWidth:'inherit',
maxHeight:'inherit',
position:'relative',
top:'50%',
left:'50%',
transform: 'translate(-50%,-50%)',
    }
}

export default React.memo(Card);