import React, {useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import Navbar from './Navbar/Navbar';
import NavbarForPhone from './Navbar/NavbarForPhone';
import LeftCard from './LeftCard';
import RightCard from './RightCard';
import { Outlet } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// import './Navbar/Navbar.js';

const Layout = (props) => {

  let [showSignUp, setShowSignUp] = useState(false);

  let [showContent, setShowContent] = useState();

   let handleShowDisClaimer = () =>{
setShowContent("DisClaimer")
    setShowSignUp(true);
   } 
 
   let handleShowAboutUS = () =>{
    setShowContent("AboutUS")
        setShowSignUp(true);
       } 

let handleClose = () =>{
  setShowSignUp(false);
 } 


const phone = /Android|webOS|iPhone|iPad|BlackBerry|IEMobile|Opear Mini/i.test(navigator.userAgent);

console.log("new width"+phone);


const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (

    <Fragment>       
         <div className={ phone || windowSize.innerWidth<500 ? "boxforPhone" : "box"}>
       <section>
         {(phone || windowSize.innerWidth<500)?<NavbarForPhone   count={props.count} setCount={props.setCount} userID={props.userID}/>:<Navbar  count={props.count} setCount={props.setCount} userID={props.userID}/>}
      <Outlet/>
  </section>
 </div>
    <footer>
    Copyright 2022 by TravBar.com. All Rights Reserved.
    
   <br/>

   <div >
    <div> 
    <a onClick={handleShowDisClaimer} to="/">
         WEBSITE DISCLAIMER
  </a>
  </div>
    <div>
      <a onClick={handleShowAboutUS} to="/">
         About US
  </a></div>
</div>

  
<Dialog

PaperProps={{ sx: { width: "30%" } }}
open={showSignUp}  onClose={handleClose}>
  {
  showContent==="AboutUS"?
 <> <DialogTitle>关于我们:</DialogTitle>
  <DialogContent>
  
旅游吧，攻略客！全球华人读写旅游信息、互换生活智慧的网络平台。
事实是，英语表达不尽兴，中文表达受限制：微信受困朋友圈，抖音油管制作耗时，要在其他中文平台找到旅游攻略好似大海捞针难上加难......越来越多的华人期盼一个专门用中文交流旅游攻略的网站。
为解决这些问题，TravBar旅游吧应运而生。在这里，你可以和亲朋好友以及素未谋面但志同道合的世界华人一起，尽情用母语互换旅游经历和经验。攻略、心得、贴士、见闻、故事应有尽有，帮助别人的同时成全自己，省时省力省钱有趣，交流的同时亦可交友。
足不出户走世界，精彩人生一键门。
来吧，快快加入旅游吧的大家庭：就在今天，只需两分钟就可免费注册，立刻上传你的美文、美图、和美拍，和全世界分享你的快乐！

      </DialogContent>
   </>   :<>
<DialogTitle>WEBSITE DISCLAIMER:</DialogTitle>
<DialogContent>
The information provided by TravBar (“we,” “us”, or “our”) on https://www.travbar.com/ (the “Site”) is for general informational purposes only. 
All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, 
regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site. 
UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A 
RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.

TESTIMONIALS DISCLAIMER

The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. YOUR INDIVIDUAL RESULTS MAY VARY.

The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public.

The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.
 
    </DialogContent>
</>
}
  
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>         
        </DialogActions>
      </Dialog>
    </footer>
    </Fragment>
  );
}
  export default Layout;