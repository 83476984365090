import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import PostEditor from "./PostEditor";
import VideoForm from "./VideoForm";

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  //const radioGroupRef = React.useRef<HTMLElement>(null);
///////////////////////////////
  let onPostFormSubmit = (e) => {
    //e.preventDefault();
    //handleClose();
    onClose();
    //window.location.reload();
    // props.setCount(props.count+1);
  };
  
  
  // const [postLocation, setPostLocation] = useState("");
  
  const [selectedOption, setSelectedOption] = useState('Text');
  
    const onValueChange=(event) =>{
      setSelectedOption(event.target.value);
      //console.log("radio post type"+event.target.value)
    }
  ///////////////////////
  
    

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

//   const handleEntering = () => {
//     if (radioGroupRef.current != null) {
//       radioGroupRef.current.focus();
//     }
//   };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setValue((event.target as HTMLInputElement).value);
//   };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
   
      open={open}
      {...other}
    >
      <DialogTitle>Phone Ringtone</DialogTitle>
      <DialogContent dividers>
       
   {props.userID===0&& "发帖需要登陆" }
   { props.userID!=0&& <form onSubmit={onPostFormSubmit}>
    
   <fieldset className="postEditor">
    <legend>发帖</legend>
        
    <dl>

<dt>帖类:</dt>
<dd>
  <div onChange={onValueChange.bind(this)}>
        <input type="radio" value="Video" defaultChecked={selectedOption==='Video'} name="PostType"/> 视频
        &nbsp;<input type="radio" value="Text" defaultChecked={selectedOption==='Text'} name="PostType"/>文本/图片
      </div>
</dd>
</dl>
      {selectedOption==='Text' ? <PostEditor userID={props.userID} /> :<VideoForm  userID={props.userID} />} 
    
</fieldset>
    </form>
     }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

const SubmitEditorModal=()=> {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('Open');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
   
    <div>
    <button onClick={handleClickListItem}>post</button>
  <ConfirmationDialogRaw
    id="ringtone-menu"
    keepMounted
    open={open}
    onClose={handleClose}
    value={value}
  />
</div>  

  );
};
export default SubmitEditorModal