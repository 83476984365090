import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //  <React.StrictMode>
    //<BrowserRouter>
     <App />
    //</BrowserRouter>
   
  //  </React.StrictMode>
);

//https://www.pluralsight.com/guides/how-to-open-and-close-react-bootstrap-modal-programmatically
//https://www.geeksforgeeks.org/how-to-use-bootstrap-with-react/
//npm install -S react-router-dom
//npm install axios --save

//npm install bootstrap
//npm install jquery popper.js
//bootstrap reactstrap
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
