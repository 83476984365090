import Layout from "./Components/Layout"
import LoginModal from "./Components/LoginModal"
import { Link, Route, Routes,Navigate, Outlet, BrowserRouter, useSearchParams } from "react-router-dom";
import PostEditorModal from './Components/PostEditModal'
import UserPost from './Components/UserPost'
import DeleteComment from './Components/DeleteComment'
import PostList from './Components/PostList';
import PopularPosts from './Components/PopularPosts';
import PopularVideos from './Components/PopularVideos';
import MyPost from './Components/MyPost';
import CreatePost from './Components/CreatePost';
import Follows from './Components/Follows';
import Follow from './Components/Follow';
import ContactUs from './Components/ContactUs';
import Deck from './Components/Deck';
import SignUpDone from './Components/SignUpDone';
import SearchResult from './Components/SearchResult';
import 'draft-js/dist/Draft.css';
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { memo } from "react";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SubmitComment from './Components/SubmitComment';
// import MyFiles from "./Components/MyFiles";
 
const App = (props) => {
  const [count, setCount] = useState(0);
 
const [userid, setUserID] = useState(0);

  const tokenString = localStorage.getItem('token');
  
  useEffect(() => {
     if(tokenString)
  {
    const userToken = JSON.parse(tokenString);
    setUserID(userToken.Id)
  } else{
    setUserID(0)

  }
  }, []);

//console.log("In App"+userid)
  return (
  <BrowserRouter>
  <Routes>
  <Route element={<Layout count={count} setCount={setCount}  userID={userid}/>}>             
  <Route path="MyPost" element={<MyPost userID={userid}/>} /> 
  <Route path="/" element={<PostList count={count} setCount={setCount} userID={userid}/>}/>
  <Route path="/UserPost/:value" element={<UserPost  userID={userid}/>}/>
  <Route path="/SearchResult/:searchText" element={<SearchResult  userID={userid}/>}/>
  <Route path="/PostEditor/:value" element={<PostEditorModal userID={userid}/>}/>
  <Route path="/CreatePost" element={<CreatePost userID={userid}/>}/>
  <Route path="/DeleteComment/:value" element={<DeleteComment />}/>
  <Route path="/PostComments/:postID/:postType/:userID/:CommentID" element={<SubmitComment   userID={userid}/>}/>
  <Route path="/PopularPosts" element={<PopularPosts  userID={userid}/>}/> 
  <Route path="/PopularVideos" element={<PopularVideos  userID={userid}/>}/> 
  <Route path="/ContactUs" element={<ContactUs  userID={userid}/>}/> 
  <Route path="/Deck" element={<Deck/>}/>   
  <Route path="/SignUpDone" element={<SignUpDone/>}/> 
  <Route path="/Follows/:useridstring" element={<Follows  userID={userid}/>}/>
  <Route path="/Follow/:useridstring/:displayname" element={<Follow  userID={userid}/>}/>
 
  </Route>
</Routes>

</BrowserRouter>
);
}
export default App;
