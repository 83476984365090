import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {  useEffect,  useState,useCallback, Fragment,createContext } from "react";
import {  } from "react-router-dom";
import { useLocation } from 'react-router'
import PostEditor from "./PostEditor";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch, useNavigate, useParams
} from "react-router-dom";
import axios from "axios";

//https://www.geeksforgeeks.org/how-to-pass-data-from-one-component-to-other-component-in-reactjs/
//const PostContext = createContext();
const PostEditModal = ({RefreshState,userID,postID}) => {
//let { value } = useParams(); 
// let [showEditor, setShowEditor] = useState(false);
// let handleClose = () => setShowEditor(false);
// let handleShow = () => setShowEditor(true);


const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};


//console.log(postID +"Post Edit Modal PostID propsvalue"+ userID)
// const [postLocation, setPostLocation] = useState("");
let onPostFormSubmit = (e) => {
  
  RefreshState(); 
};


return(

<React.Fragment>  
   
<button className="ovalbutton"  onClick={handleClickOpen}>
编辑
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */} 
          <form onSubmit={onPostFormSubmit}> 
          <fieldset className="postitem">
    <legend>发帖</legend>
          

   <PostEditor userID={userID} postID={postID} />
   </fieldset>
   </form>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
</React.Fragment>
)
 };

export default PostEditModal
// export {PostContext};