import React from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { domainURI } from "../config";
class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      Email: '',
      Message: ''
    }
  }
  
  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:domainURI+"/api/ContactUsForms",
      data:  this.state
    }).then((response)=>{
//console.log(response)
      if (response.status === 200) {
        alert("Message Sent.");
        this.resetForm()
      } else  {
        alert("Message failed to send.")
      }
    })
  }
  resetForm(){
    this.setState({UserName: '', Email: '', Message: ''})
  }
  render() {
    return(
      <fieldset className="postitem">
      <legend>联系我们</legend>
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
        
        <dl  className="foralldl">
          <dt className="formdt">名字:</dt>
<dd className="formdd">            
              <input type="text" className="form-control" id="name" value={this.state.UserName} onChange={this.onNameChange.bind(this)} />
          </dd>


         <dt className="formdt">电邮:</dt>
<dd className="formdd">            
             
              <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.Email} onChange={this.onEmailChange.bind(this)} />
          </dd>
          <dt className="formdt">信息:</dt>
<dd className="formdd">            
             
              <textarea className="form-control" rows="5" id="message" value={this.state.Message} onChange={this.onMessageChange.bind(this)} />
          </dd>
          <dt className="formdt"></dt>
          <dd className="formdd">
            <ReCAPTCHA
     
          sitekey="6LdG1qwiAAAAAKdUIqRYEB6e0uDzR848s05NS72x"
        
        />
          </dd>
          <dt className="formdt">
             
          </dt>
          <dd className="formdd">  <button type="submit" className="btn btn-primary">Submit</button>  </dd>

          </dl>
        
        </form>
        </fieldset>
    );
  }
  onNameChange(event) {
	  this.setState({UserName: event.target.value})
  }
  onEmailChange(event) {
	  this.setState({Email: event.target.value})
  }
  onMessageChange(event) {
	  this.setState({Message: event.target.value})
  }
}
export default ContactUs;