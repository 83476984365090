   import React, {  useEffect,  useState,useCallback, Fragment } from "react";
   import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch, useNavigate
  } from "react-router-dom"; 
  
  import Modal from '@mui/material/Modal';
 import Button from '@mui/material/Button';
 import TextField from '@mui/material/TextField';
 import Dialog from '@mui/material/Dialog';
 import DialogActions from '@mui/material/DialogActions';
 import DialogContent from '@mui/material/DialogContent';
 import DialogContentText from '@mui/material/DialogContentText';
 import DialogTitle from '@mui/material/DialogTitle';
 import axios from 'axios';
 import { useToken } from '../auth/useToken';
//https://jasonwatmore.com/post/2021/12/14/net-6-jwt-authentication-tutorial-with-example-api#users-controller-cs
//https://react-bootstrap.github.io/components/modal/
import { domainURI } from "../config";
const SignUpModal = props => {

   const [errorMessage, setErrorMessage] = useState('');
    let [showSignUp, setShowSignUp] = useState(false);
     const [token, setToken] = useToken();
   
    let handleShow = (e) => {
      e.preventDefault();
      setShowSignUp(true);
    }
      let navigate = useNavigate();
    
        const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
        const [email, setEmail] = useState("");
        const [password, setPassword] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");
        const [firstname, setFirstName] = useState("");
        const [lastname, setLastName] = useState("");
        const [displayname, setDisplayName] = useState("");

 let handleClose = () =>{
 setConfirmPasswordValue("");
setEmail("");
        setPassword("");
        setConfirmPassword("");
        setFirstName("");
        setLastName("");
        setDisplayName("");
        setErrorMessage("")
     setShowSignUp(false);
    } 
   const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
      // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
    let onSignUpFormSubmit = (e) => {
      e.preventDefault();
      if(regEx.test(email) && firstname && lastname && displayname && email && password && confirmPassword)
      {


     
      const response = axios.post(domainURI+'/odata/Users', 
      {
        "Email":email,"Password":password,"LastName":lastname,"FirstName":firstname,"DisplayName":displayname,"UserName":email
      }
            
            
            
            )
            .then(function (response) {

              if(response.data.value==="Existing User.")
              {
                setErrorMessage("Email is already in use.")
              }else{
                  
                
                  // const { token } = response.data;
                  // setToken(token); 
                  //console.log(token)
                  handleClose();
                  navigate("/SignUpDone")
                
              }
              //handle success
             
          
     
              console.log(response.data);
            })
            .catch(function (response) {
              //handle error
              setErrorMessage("Something has happened.")
             //console.log(response);
            });
     }else{

      if(regEx.test(email))
      {
setErrorMessage("Please fill out all the fields.")
     // alert("Please fill out all the fields.")
      }else{

        setErrorMessage("Invalid email.")
      }
      
     }        
    };
    return(
  
<>
  

  <a  className="nav-link"   onClick={(e)=>handleShow(e)} >
          <i className="fa fa-fw fa-user-plus">
            
            </i> 注册
  </a>



<Dialog

PaperProps={{ sx: { width: "30%" } }}
open={showSignUp}  onClose={handleClose}>
  <DialogTitle>注册</DialogTitle>
  <DialogContent>
  <fieldset className="postitem">
    <legend>请输入以下信息</legend>

 {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

  <form>


  <dl className="foralldl">

<dt  className="formdt">名*:</dt>
<dd  className="formdd"> <input type="text" 
    value={firstname} required
    onChange={e => setFirstName(e.target.value)}
    placeholder="Enter First Name" />
</dd>
      
<dt className="formdt">姓*:</dt>
<dd className="formdd"> <input type="text" 
    value={lastname} required
    onChange={e => setLastName(e.target.value)}
    placeholder="Enter Last Name" />  
</dd>
  
   
<dt className="formdt">显示名*:</dt>
<dd className="formdd"> <input type="text" 
    value={displayname}
    onChange={e => setDisplayName(e.target.value)}
    placeholder="Enter Display Name" />   
</dd>  
<dt className="formdt">邮件:</dt>
<dd className="formdd"> <input type="email"  
    value={email}
    onChange={e => setEmail(e.target.value)} placeholder="Enter email" />
</dd>  

<dt className="formdt">密码*:</dt>
<dd className="formdd"> <input type="password"
    value={password}
    onChange={e => setPassword(e.target.value)}
    placeholder="Password" />
</dd>  

<dt className="formdt">密码确认*:</dt>
<dd className="formdd"> <input type="password"
    value={confirmPassword}
    onChange={e => setConfirmPassword(e.target.value)}
    placeholder="Password" />
</dd>   
</dl>    
   
  <button onClick={onSignUpFormSubmit} type="button">
    提交
  </button>
</form>
 
</fieldset>
      </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
</>

      
        
    )
     };
    
  export default SignUpModal