//Navbar.js
import React,  {  useEffect,  useState,useCallback, Fragment } from 'react';
import SignUpModal from "../SignUpModal"
import LoginModal from "../LoginModal"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
 useRouteMatch,useNavigate, Navigate
} from "react-router-dom";
import { domainURI } from "../../config";
import SubmitEditorModal from '../SubmitEditorModal';
import './Navbar.css';
//https://www.w3schools.com/howto/howto_js_topnav_responsive.asp
//https://devblogs.microsoft.com/premier-developer/deploying-react-apps-to-azure-with-azure-devops/
const Navbar=(props) => {
  // https://github.com/dalalRohit
  const [scrolled,setScrolled]=React.useState(false);

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 200 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }
  useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })

  let x=['topnav'];
  if(scrolled){
    x.push('scrolled');
  }



   
  const [searchText, setSearchText] = useState("");
  let navigate = useNavigate();

  let onSearchFormSubmit = (e) => {
   //e.preventDefault();
   if(searchText)
   {
    //alert("input something"+searchText)
    navigate("/SearchResult/"+searchText) 
   }else{
    alert("input something")
   }
    //console.log("token in login:"+email+password)
   //  alert("seach button clicked.")
    
  };


  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  return (
   
  <header className={x.join(" ")}>
        <div className="topnav" id="myTopnav">     
          {
            props.userID!=0 &&<a href={"/UserPost/"+props.userID}>              
              <img className='UserImg' src={domainURI+"/api/photos/"+props.userID} alt="没照片" 
              style={{ width: 25,height:25 }}  onError={e => { e.currentTarget.src = domainURI+"/api/photos/10"
              }} />
            </a>
          } 
      <a  href="/">最新</a>
      <a  href="/PopularPosts">流行文本</a>
      <a   href="/PopularVideos">流行视频</a>
      <a   href="/MyPost">我的贴</a>    
      <a   href="/CreatePost">发帖</a>    
      {/* <SubmitEditorModal userID={props.userID}/>   */}
      <div className="search-container">
        <form inline="true">          
              <input type="search" placeholder="搜索 ..."  value={searchText} onChange={(e) => setSearchText(e.target.value)}/>              
                <button type="submit" onClick={(e)=>onSearchFormSubmit(e)} color="info"><i className="fa fa-fw fa-search"></i></button>         
        </form>
      </div>
      <LoginModal userID={props.userID}/>
      {
        props.userID===0&&<SignUpModal/>
      }          
      <a href="/ContactUs"><i className="fa fa-fw fa-envelope"></i>联系我们</a>
      <a href="" className="icon" onClick={()=>myFunction()}>
        <i className="fa fa-bars"></i>
      </a>
    </div>
    </header>
  )
};

export default Navbar;