
import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react"; 
import axios from "axios";
import { domainURI } from "../config";
const FollowButton = ({authorID,userID}) => {

  const [ followed, setFollowed ] = useState(false);

const fetchFollowData = useCallback(()=>{
  
  const response = axios({ method: 'get', url: domainURI+'/api/Follows?UserID='+userID+'&AuthorID='+authorID,
   headers: {
  'Access-Control-Allow-Credentials':true,
  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json; charset=utf-8'
  }, responseType: "json"})
  .then(function (response) {
         console.log("follow"
         +response.data)
         setFollowed(response.data);
   })
   .catch(function (response) {
         
        });
      },[]) 



      useEffect(() => {
        fetchFollowData();    
      }, [authorID]);
   
     
const FollowButtonClick = (a) => {
  

  const response = 
  axios({ method: "POST", url: domainURI+"/api/Follows", data: {         
    "AuthorID":JSON.stringify(authorID),
    "UserID":userID,
    "Unfollowed":followed  
  }, headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }}).then(function (response) {
  
    
  })
  .catch(function (response) {
  
  });
  

  setFollowed(!followed);

  }
 

return(
        <>      
        <button  className="ovalbutton" onClick={() => (userID === undefined || userID === null||userID===0)?alert("关注需登录"):FollowButtonClick()}>{followed ? "取关": "关注"}</button>         
        </>
    )
  };
   
export default FollowButton