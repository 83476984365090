import 'draft-js/dist/Draft.css';
import draftToHtml from "draftjs-to-html";
import 'draft-js/dist/Draft.css';
import { Editor, EditorState, convertToRaw,convertFromRaw } from "draft-js";
import React, {  useEffect,  useState,useCallback, Fragment } from "react";
import axios from "axios";
import { memo } from "react";
import PostItem from './PostItem';
import { domainURI } from "../config";
import VideoItem from './VideoItem';

function PopularPosts(props){
  let [morePostList, setMorePostList] = useState([]);
  let [PostList, setPostList] = useState([]);
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(5); 
  let [refresh, setRefresh] = useState([]);
//let [PopularPostList, setPopularPostList] = useState([]);
          
const fetchPopularPostData = useCallback(()=>{
// axios
// .get(
//   'https://localhost:44340/api/PopularPosts',{
//      headers: { 
//       'Accept': 'application/json',
//       'Access-Control-Allow-Credentials':true,
//     'Access-Control-Allow-Origin' : '*',
//     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//   }, responseType: "json",
//   }
 
// )
//  .then(response=>{
//  //console.log("subdata"+response.data);
//  setPopularPostList(response.data)
// })
// .catch(error => {
// console.log(
//   "Encountered an error with fetching and parsing data",
//   error
// );
// }
// );

// },[]) 


// useEffect(() => {
//   fetchPopularPostData()
// }, [Location.key]);

axios.get(domainURI+'/api/PopularPosts?fileType=0&pageNumber='+pageNumber+'&pageSize='+pageSize,
{
  headers: { 
    'Accept': 'application/json',
    'Access-Control-Allow-Credentials':true,
    'Access-Control-Allow-Origin' : 'https://www.travbar.com',
    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   }, responseType: "json",
} 
).then(response=>{
//console.log("subdata"+JSON.stringify(response.data));
setPostList(response.data)
setMorePostList(morePostList => [...morePostList, ...response.data]);
})
.catch(error => {
alert("Encountered an error with fetching and parsing data", error);
}
);
},[refresh,props.userID,pageNumber]) 

useEffect(() => {
//if(props.userID!=0)
fetchPopularPostData();
}, [refresh,pageNumber]);

const RefreshState = () => {
setRefresh((c) => c + 1);
//fetchPostData();
//window.location.reload();
//console.log("refreshed"+refresh)
};

const onMoreButtonClick = () => {
setPageNumber(pageNumber+1);
}


return(
<>
      {/* <div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "left" }} >

        {PopularPostList.map((p) =><PostItem key={p.ID}    userID={props.userID}  value={{ key: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime, Text: draftToHtml(JSON.parse(p.Text)) }} />)}
     
     
     </div> */}

<div style={{ backgroundColor:"AliceBlue", padding: "10px", fontSize: "16px", textAlign: "left" }} > 
    {
      morePostList.map((p) =>(p.FileType===0)?
      <PostItem key={p.ID} userID={props.userID} RefreshState={RefreshState} value={{ PostID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
      :
      <VideoItem key={p.ID} userID={props.userID}  RefreshState={RefreshState} value={{ VideoID: p.ID,UserID:p.UserID, PostedDate:p.PostDateTime,PostLocation:p.Location, Text: p.Text }} />
     )
    }
    </div>
    {
      (PostList.length ? ((PostList.length<pageSize)? "": <button className="ovalbutton"  onClick={()=>onMoreButtonClick()}>下一页</button>):"下载中，请耐心等待。。。")
    }    
    {/* { props.userID===0 && <div className="fail">Uh oh... you need login or you don't have any posts</div> }  */}
</>
)
  
}

export default PopularPosts