
import React, {  Component,useRef, useEffect,  useState,useCallback, Fragment } from "react"; 
import { useNavigate } from "react-router-dom";
import PostEditModal from "./PostEditModal";
import LikeButton from "./LikeButton";
import FollowButton from "./FollowButton";
import PostCommentButton from "./PostCommentButton";
import DeletePostButton from "./DeletePostButton";
import SubmitComment from "./SubmitComment";
import axios from 'axios';
import draftToHtml from "draftjs-to-html";
import { domainURI } from "../config";
const PostItem =  ({ userID, RefreshState, value}) => {
  
  //show local time
  const date = new Date();
  const offsetInHours = date.getTimezoneOffset() / 60; 
  const subtractHours=(date, hours)=> {
    date.setHours(date.getHours() - hours);
  
    return date;
  }
//country time format? later

  let [showCommentList, setShowCommentList] = useState(false);
  let [refreshCount, setRefreshCount] = useState(false);
  // console.log("postItem"+userID)
  const RefreshShowCommentListState = () => {
      setShowCommentList(!showCommentList);
  };

  const RefreshShowState = () => {
    setRefreshCount(!refreshCount)
    setShowCommentList(true);
  };

  const [user,setUser] = useState(null);
  useEffect(()=>{
     // api/UsersAPI/5
     value.UserID&&(async()=>{
    //  const response=await axios.get('https://localhost:44340/odata/Users('+props.userID+')');
  const response=await axios.get(domainURI+'/api/UsersAPI/'+value.UserID);
  
  setUser(response.data)
  //console.log(response.data)
  
  })(value.UserID);
  },[value.UserID]);
const { hobbies, ID,Email,Password,LastName,FirstName,DisplayName,UserName,EmailVerified,Bio,Location,WebSite,WeChat,FaceBook,Telegram}=user||{};

return(
        <>         
        
        
        <fieldset className="postitem">
  <legend> <a  className="text-align:right; float:right;text-decoration:none;"  href={"/UserPost/"+value.UserID }>
         {DisplayName}&nbsp;<img className='avatar' src={domainURI+"/api/photos/"+value.UserID} alt="avatar"   onError={e => {
          e.currentTarget.src = domainURI+"/api/photos/10"
        }} />
        </a> 
        &nbsp;
        {value.PostLocation} &nbsp;发布于&nbsp;
        {
            // new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit',hour: 'numeric',  minute: 'numeric', second: 'numeric'}).format(subtractHours(new Date(value.PostedDate), offsetInHours))
            subtractHours(new Date(value.PostedDate), offsetInHours).toLocaleString()
        }
       
         &nbsp;&nbsp;&nbsp;&nbsp;
         {value.UserID!==userID && <FollowButton authorID={value.UserID} userID={userID}></FollowButton>}
        </legend>
        <div className="EditorDiv"  dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(value.Text)) }} />
       <nav>      
         {                   
           value.UserID==userID ? <PostEditModal  RefreshState={RefreshState} userID={userID} postID ={value.PostID} /> : null
         }                          
         {                 
           value.UserID==userID ? <DeletePostButton  userID={userID} RefreshState={RefreshState} postID={value.PostID} ></DeletePostButton> : null
         }           
      
        <LikeButton userID={userID}  postID={value.PostID} postType="false"></LikeButton>
      
<PostCommentButton refreshCount={refreshCount} RefreshShowCommentListState={RefreshShowCommentListState}  userID={userID}  RefreshState={RefreshState}  postType="false" postID={value.PostID}/>              
    
      </nav>
   </fieldset>
      </>
    )
  };
   
export default PostItem